import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ButtonIconDirective } from './button-icon/button-icon.directive';
import { ButtonComponent } from './button/button.component';
import { DlsIconModule } from '@zelis/dls/icons';
import { ButtonDirective } from './button/button.directive';
import { DlsResponsiveTypographyModule } from '@zelis/dls/responsive-typography';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    DlsIconModule,
    DlsResponsiveTypographyModule,
    MatProgressSpinnerModule,
    RouterModule.forChild([]),
  ],
  declarations: [ButtonIconDirective, ButtonComponent, ButtonDirective],
  exports: [
    ButtonIconDirective,
    ButtonComponent,
    ButtonDirective,
    MatButtonModule,
  ],
})
export class DlsButtonModule {}
