import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkAlphaPrefixDialogComponent } from './network-alpha-prefix-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { TokenizedInputModule } from '@zelis/platform-ui-components';
import { MemberCardModule } from '@zelis/platform-ui-components';
import { ReactiveFormsModule } from '@angular/forms';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';
import { DlsIconModule } from "@zelis/dls/icons";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatSelectModule,
    TokenizedInputModule,
    MemberCardModule,
    ReactiveFormsModule,
    UnfocusModule,
    DlsIconModule
  ],
  declarations: [NetworkAlphaPrefixDialogComponent],
  exports: [NetworkAlphaPrefixDialogComponent],
})
export class NetworkAlphaPrefixDialogModule {}
