import { Injectable } from '@angular/core';
import { SearchFiltersFacets } from '@interfaces/search-filters-facets.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { CustomFilterOptions } from '@components/+search/classes/custom-filter-options.class';
import { find, each, orderBy, cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchFiltersService {
  public filterConfig: SearchFilter[];
  public dropdownItems: BehaviorSubject<SearchFilter[]> = new BehaviorSubject(
    []
  );
  public checkboxItems: BehaviorSubject<SearchFilter[]> = new BehaviorSubject(
    []
  );
  public nestedDropdownItems: BehaviorSubject<SearchFilter[]> =
    new BehaviorSubject([]);
  public locationGeoItem: BehaviorSubject<SearchFilter> = new BehaviorSubject(
    new SearchFilter({})
  );

  private customFilterOptions = new CustomFilterOptions();
  private validAnpValues = ['Y', 'O', 'OX', 'XO', 'EO', 'OE', 'F'];

  public matchFacetOptionsForStoreData(
    facets: SearchFiltersFacets,
    filteredByType: SearchFilter[],
    tierCode: string
  ): SearchFilter[] {
    facets = new SearchFiltersFacets(facets, tierCode);
    if (!facets) {
      return filteredByType;
    }
    return this.mapFilters(cloneDeep(facets), cloneDeep(filteredByType));
  }

  public matchFacetOptions(
    facets: SearchFiltersFacets,
    filteredByType: SearchFilter[],
    tierCode: string
  ): void {
    facets = new SearchFiltersFacets(facets, tierCode);
    if (!facets) {
      this.filterConfig = filteredByType;
      return;
    }
    this.filterConfig = this.mapFilters(facets, filteredByType);
  }

  // replace with store?
  public filterMatchedFacets(): void {
    this.nestedDropdownItems.next(
      this.filterConfig.filter((item) => item.type === 'nested_dropdown')
    );
    this.dropdownItems.next(
      this.filterConfig.filter((item) => item.type === 'dropdown').slice(0, 6)
    );
    this.checkboxItems.next(this.filterCheckboxItems());
    this.locationGeoItem.next(
      find(this.filterConfig, { facet: 'location_geo' })
    );
  }

  // TODO: replace with store stuff
  public resetMatchedFacets(): void {
    this.nestedDropdownItems.next([]);
    this.dropdownItems.next([]);
    this.checkboxItems.next([]);
    this.locationGeoItem.next(new SearchFilter({}));
  }

  public mapFacetOptionName(filter: SearchFilter): any {
    const foundOptions = [];

    each(filter.option_mapping, (name, value) => {
      const foundOption = find(filter.options, { value: value });

      if (foundOption) {
        foundOption.name = name;
        foundOptions.push(foundOption);
      }
    });

    return foundOptions;
  }

  // Maps facets and filters to together with an extra loop for "More Filters"
  private mapFilters(
    facets: SearchFiltersFacets,
    config: SearchFilter[]
  ): SearchFilter[] {
    return config.map((filter) => {
      if (filter.facet === 'provider_type_description' && filter.selected) {
        filter.selected = decodeURIComponent(filter.selected);
      }
      this.mapFilterOptions(filter, facets[filter.facet]);

      // More filters
      filter.items.forEach((item: SearchFilter) =>
        this.mapFilterOptions(item, facets[item.facet])
      );

      return filter;
    });
  }

  private mapFilterOptions(filter: SearchFilter, filterOptions: any): void {
    if (!filterOptions) {
      filter.options = [];
      return;
    }

    // Maps all the selected filters options + all other options with the above facets and
    // maps them together

    filter.options = this.filterOptions(filterOptions, filter.selected);
    const tiersFacet = filter.facet.split(':');

    switch (true) {
      case !!filter.option_mapping:
        filter.options = this.mapFacetOptionName(filter);
        break;
      case !!filter.option_sort:
        filter.options = this.customFilterOptions[
          filter.option_sort + 'OptionSort'
        ](filter.options);
        break;
      case tiersFacet[0].indexOf('tiers') > -1:
        filter.options.forEach((option) => {
          if (option.value !== null) {
            option.name =
              'tier_' + tiersFacet[1].toUpperCase() + '_' + option.value;
          }
        });
        break;
      default:
        filter.options = orderBy(
          filter.options,
          [(option) => option.name.toString().toLowerCase()],
          ['asc']
        );
    }

    this.addNoneOption(filter);
  }

  private filterCheckboxItems(): SearchFilter[] {
    const allCheckboxFilters = this.filterConfig.filter(
      (filter) => filter.type === 'checkbox'
    );

    const validCheckboxFilters = allCheckboxFilters.filter((checkboxItem) => {
      return checkboxItem.options.find((option) => {
        if (checkboxItem.facet === 'contract_accepting_new_patients') {
          return this.validAnpValues.indexOf(option.value) !== -1;
        }
        return option.value === 'true' || option.value === 'Y';
      });
    });

    return validCheckboxFilters;
  }

  // filters out the filters with a selected
  private filterOptions(filterOptions, selected): any {
    return filterOptions.filter((option) => {
      if (option.value || String(option.value) === String(selected)) {
        option.value = option.value.toString();
        return option;
      }
    });
  }

  // removes filter from a option if value is null
  private addNoneOption(filter: SearchFilter): void {
    filter.options.unshift({
      value: null,
      name: filter.name,
    });
  }
}
