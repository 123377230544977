import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StickyContactFabComponent } from './sticky-contact-fab.component';
import { ChatButtonModule } from '../pat-chat/chat-button/chat-button.module';
import { ChatMessageModule } from '../pat-chat/chat-message/chat-message.module';
import { ChatModule } from '../pat-chat/chat/chat.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProactiveChatModule } from '../pat-chat/proactive-chat/proactive-chat.module';
import { PassiveChatModule } from '../pat-chat/passive-chat/passive-chat.module';

@NgModule({
  exports: [StickyContactFabComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ChatMessageModule,
    ChatModule,
    ChatButtonModule,
    TranslateModule,
    ProactiveChatModule,
    PassiveChatModule,
  ],
  declarations: [StickyContactFabComponent],
})
export class StickyContactFabModule {}
