<div class="uux-questionnaire-container">
  <h4
    class="text-center pad all-3x"
    [innerHTML]="'uux_subheader_questionnaire' | translate"
  ></h4>

  <div
    *ngFor="let option of questionnaireOptions;
    trackBy: trackByFnIndex;
    let i = index"
    class="segment w-100"
    [attr.data-cy]="'uux.questionnaire-option.' + i"
    mat-menu-item
    fxLayout="row"
    fxLayoutAlign="space-between center"
    (click)="selectMarketSegment(option.replace(' ', ''))"
    appGtm
    [gtmOnClick]="true"
    [gtmData]="{
      event: 'find-your-plan',
      label: 'uux_questionnaire_' + option.replace(' ', '') | translate
    }"
  >
    <div fxFlex="grow">{{ 'uux_questionnaire_' + option.replace(' ', '') | translate }}</div>
    <div fxFlex fxFlexAlign="end">
      <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
    </div>
  </div>
</div>
