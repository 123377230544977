export class LnpSetting {
  loaded?: boolean;
  loadTime?: number;
  dependencies: object;

  constructor(obj?: LnpSetting) {
    if (obj) {
      this.loaded = obj.loaded || false;
      this.loadTime = obj.loadTime || 0;
      this.dependencies = obj.dependencies || {};
    }
  }
}

export class LnpSettings {
  search: LnpSetting;
  profile: LnpSetting;
  home: LnpSetting;

  constructor() {
    this.home = new LnpSetting({
      dependencies: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-global-header-autosuggest': 0,
      },
    });
    this.search = new LnpSetting({
      dependencies: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-search-results': 0,
      },
    });
    this.profile = new LnpSetting({
      dependencies: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-profile-header': 0,
      },
    });
  }
}
