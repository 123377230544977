export class AlphaListItem {
  public name: string;
  public id: number;
  public description: string;
  public type: string;
  public prefix: string;
  public index: number;
  public high_incentive_amount: number;
  public suppress_display: boolean;
  public episode_procedure: boolean;
  public times_performed: number;
  public locale_name: string;
  public originalItem: any;

  constructor(item: any, lang?: string, type?: string) {
    this.originalItem = item;
    this.id = item.id;
    if (type === 'provider_types') {
      this.setProviderTypeItem(item);
    } else if (type === 'search_specialties') {
      this.setSpecialtiesItem(item, lang);
    } else if (type === 'procedures' || type === 'incentivized_procedures') {
      this.setProceduresItem(item);
    }
    if (this.name) {
      this.prefix = this.name.toLowerCase()[0];
    }
  }

  private setSpecialtiesItem(item: any, lang: string) {
    if (item.name_translated) {
      lang = this.checkItemForLang(item.name_translated, lang);
      this.name = item.name_translated[lang] || item.name;
      this.description = item.description ? item.description[lang] : '';
      this.suppress_display = item.suppress_display || false;
      this.high_incentive_amount = item.high_incentive_amount || null;
    }
  }

  private setProceduresItem(item: any) {
    if (item.locale_name) {
      this.name = item.locale_name;
      this.description = item.description;
      this.high_incentive_amount = item.high_incentive_amount;
      this.episode_procedure = item.episode_procedure;
    }
  }

  private setProviderTypeItem(item: any) {
    this.name = item.name;
    this.description = 'app_global_provider_type';
    this.suppress_display = false;
  }

  private checkItemForLang(translations: any, lang: string) {
    return translations[lang] ? lang : 'en';
  }
}
