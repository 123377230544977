import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModifyPreviousSelectionsComponent } from './modify-previous-selections.component';
import { TranslateModule } from "@ngx-translate/core";
import { DlsListItemModule } from "@zelis/dls/list-item";
import { DlsButtonModule } from "@zelis/dls/button";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { WizardLoginLinkModule } from "@components/network-selection-wizard/wizard-login-link/wizard-login-link.module";

@NgModule({
  declarations: [ModifyPreviousSelectionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DlsListItemModule,
    DlsButtonModule,
    MatListModule,
    WizardLoginLinkModule
  ],
  exports: [ModifyPreviousSelectionsComponent],
})
export class ModifyPreviousSelectionsModule {}
