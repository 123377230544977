<div
  fxLayout="row"
  class="footer-trigger text-center"
  data-cy="sticky-contact-fab"
>
  <div
    *ngIf="(showBubble | async) && (chatStarted | async) === false"
    class="content"
    [ngClass.lt-md]="'mobile-content'"
    data-cy="sticky-contact-fab.content"
  >
    <div *ngIf="displayProactive | async">
      <app-proactive-chat (closeBubble)="toggleBubble(false)">
      </app-proactive-chat>
    </div>
    <div *ngIf="(displayProactive | async) === false">
      <app-passive-chat> </app-passive-chat>
    </div>
  </div>

  <button
    mat-fab
    tabindex="0"
    [attr.aria-label]="'pat_chat_open' | translate"
    data-cy="sticky-contact-fab.button"
    (click)="toggleBubble()"
  >
    <mat-icon
      class="w-8 h-8"
      svgIcon="dls-headset-user-headset-light"
      data-cy="sticky-contact-fab.icon">
    </mat-icon>
  </button>
</div>
