<div class="mobile-autosuggest-overlay" data-cy="mobile-autosuggest-overlay">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="mobile-autosuggest-container pad top-2x bottom-2x high-contrast-theme"
  >
    <i (click)="dialogRef.close()" class="icon-arrow-1-left back"> </i>

    <div class="autosuggest-container-box" fxFlex>
      <app-global-location-select
        data-cy="mobile-location-input"
        analytics
        appearance="fill"
        [mobileOverlay]="true"
        [results]="locationAutosuggest.cities"
        [selectedOption]="
          data.locationSelected ? locationAutosuggest.selectedLocation : null
        "
        (optionSelected)="onOptionSelect()"
        (termEntered)="locationAutosuggest.onLocationEntered($event)"
        (termSelected)="locationAutosuggest.onLocationSelect($event)"
        (emitBrowserLocationRequested)="
          locationAutosuggest.requestBrowserLocation()
        "
      >
      </app-global-location-select>
    </div>
  </div>
</div>
