import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  forwardRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'platform-tokenized-input',
  templateUrl: './tokenized-input.component.html',
  styleUrls: ['./tokenized-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TokenizedInputComponent),
      multi: true,
    },
  ],
})
export class TokenizedInputComponent implements ControlValueAccessor {
  @Input()
  public set count(val: number) {
    this._count = val;
    this.tokens = this.setTokens(val, this.value);
  }
  public get count(): number {
    return this._count;
  }
  @Input()
  public set value(val: string) {
    this.tokens = this.setTokens(this.count, val);
  }
  public get value(): string {
    return this.getValue();
  }
  @Input() public uppercase = true;
  @Input() public rounded = false;
  @Input() public autocomplete = 'off'; // HTML autocomplete relay: "on" or "off"

  @Output() public innerFocus: EventEmitter<FocusEvent> = new EventEmitter();

  @ViewChildren(MatInput) private inputFields: QueryList<MatInput>;

  public tokens: string[] = [];
  public disabled = false;

  private _count = 0;
  private ignoreKeys = ['Tab', 'Shift', ' '];
  private onChange: (value: any) => void;
  private onTouched: (event: TouchEvent) => void;

  // Start: ControlValueAccessor interface implementation
  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // End: ControlValueAccessor interface implementation

  public trackByFn(index: number): number {
    return index;
  }

  public onKeyUp(event: KeyboardEvent, index: number): void {
    const value = event.target['value'];
    this.setToken(value, index);
    this.change();
    if (!value || this.ignoreKeys.indexOf(event.key) !== -1) {
      return;
    }
    this.advanceFocus(index);
  }

  public onTouch(event: TouchEvent): void {
    this.onTouched(event);
  }

  private setTokens(count: number, value: string): string[] {
    value = (value || '').replace(' ', '');
    if (this.uppercase) {
      value = value.toUpperCase();
    }
    const tokens = Array(count).fill('');
    const valueTokens = this.tokenize(value);
    return tokens.map((val: string, index: number) => valueTokens[index] || '');
  }

  private tokenize(val: string): string[] {
    val = (val || '').substring(0, this.count);
    return val.split('');
  }

  private setToken(value: string, index: number): void {
    if (this.uppercase) {
      value = value.toUpperCase();
    }
    this.tokens[index] = value;
  }

  private getValue(): string {
    return this.tokens.join('');
  }

  private change(): void {
    if (this.onChange) {
      this.onChange(this.getValue());
    }
  }

  private advanceFocus(index: number): void {
    if (index < this.count - 1) {
      const element = this.inputFields.find(
        (item: MatInput, itemIndex: number) => itemIndex === index + 1
      );
      element.focus();
    }
  }
}
