import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SearchParams } from '@interfaces/search-params.model';
import { AppParamsService } from '../app.params.service';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { SearchSummary } from '@interfaces/search-summary.interface';
import { MemberParams } from '@interfaces/member-params.model';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ConfigurationService } from '@services/configuration.service';
import { clone, extend, identity, pickBy } from 'lodash';
import { AuthService } from '../auth.service';
import { SearchParamsService } from '@components/+search/search-params.service';
import { RouteUtilities } from '@utilities/route.utilities';

@Injectable({
  providedIn: 'root',
})
export class SerpService {
  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private searchParamsService: SearchParamsService,
    private routeUtilities: RouteUtilities,
  ) {}

  /**
   * Get rates serp summary
   * @param params: SearchParams
   * @returns Observable<SearchSummary>
   */

  public getSerpResultsWhenAuthenticated(
    params: any
  ): Observable<SearchSummary> {
    return this.authService.authStatus.pipe(
      filter((auth) => auth.resolved && auth.auth_status),
      take(1),
      switchMap(() => this.requestSerpSummary(params))
    );
  }

  private requestSerpSummary(params: SearchParams): Observable<SearchSummary> {
    const url = `/api/providers/summary.json`;
    const httpParams = this.getHttpRequestParams(this.cloneParams(params));
    const encodedParams = this.routeUtilities.manuallyEncodeParams(httpParams);

    return this.configurationService.signatureResolved()
      .pipe(
        switchMap(() =>
          this.http
          .get(`${url}${encodedParams}`, {
            withCredentials: true
          })
          .pipe(map((data: SearchSummary) => data || null))
        )
      );
  }

  private getHttpRequestParams(params: SearchParams): HttpParams {
    this.extendFormattedParams(params);
    // update tiers to tiers_facetable
    this.searchParamsService.updateTiersFacetableParam(params);
    // Get truthy params only
    params = pickBy(params, identity);
    // Generate and return HttpParams object
    return new HttpParams({ fromObject: params as any });
  }

  private extendFormattedParams(params: SearchParams) {
    // Add member params
    extend(params, this.getMemberParams(params));
    // Add locale
    params.locale = this.getLocale();
    // Add transaction id
    params.transaction_id = UUID.UUID();
    // Add account id
    params.account_id = this.configurationService.account_id.getValue();
    return params;
  }

  private cloneParams(params: SearchParams): SearchParams {
    return (params = clone(params));
  }

  private getMemberParams(params): MemberParams {
    const memberParams = this.appParams.getMemberParams();
    params = new MemberParams(memberParams);
    return params;
  }

  private getLocale(): string {
    let lang = 'en';
    if (this.translate.currentLang) {
      lang = this.translate.currentLang;
    }
    return lang;
  }
}
