import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberCardComponent } from './member-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  exports: [MemberCardComponent],
  imports: [CommonModule, FlexLayoutModule],
  declarations: [MemberCardComponent],
})
export class MemberCardModule {}
