import { GuidedSearchTriggerComponent } from './guided-search-trigger.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [GuidedSearchTriggerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [GuidedSearchTriggerComponent],
})
export class GuidedSearchTriggerModule {}
