<mat-list-option
  *ngIf="selectionList"
  [checkboxPosition]="checkboxPosition"
  [color]="color"
  [selected]="selected"
  [id]="id"
>
  <mat-radio-button
    *ngIf="!selectionList.multiple"
    matListIcon
    [value]="primaryText"
    [checked]="checked | async"
    [color]="color"
    disableRipple
  ></mat-radio-button>

  <mat-icon
    matListIcon
    *ngIf="leftIconName"
    [svgIcon]="leftIconName"
  ></mat-icon>

  <zelis-avatar
    matListAvatar
    *ngIf="avatarSrc"
    [src]="avatarSrc"
    [placeholderIcon]="avatarPlaceholderIcon"
    size="small"
  ></zelis-avatar>

  <p
    matLine
    class="text-base"
    [zelisHighlight]="highlightTerm"
    data-cy="list-item-primary-text"
    [innerHTML]="primaryText"
  ></p>

  <p
    *ngIf="secondaryText"
    matLine
    class="secondary-text"
    data-cy="list-item-secondary-text"
    [innerHTML]="secondaryText"
  ></p>

  <div class="flex items-center ml-4 space-x-4">
    <zelis-theme-lozenge
      [color]="lozengeColor"
      [emphasis]="lozengeEmphasis"
      size="medium"
      [type]="lozengeType"
      [classes]="lozengeClasses"
      *ngIf="
        lozengeType === 'theme' &&
        lozengeColor &&
        lozengeEmphasis &&
        lozengeText
      "
      >{{ lozengeText }}</zelis-theme-lozenge
    >
    <zelis-low-emphasis-gray-lozenge
      *ngIf="lozengeType === 'low-emphasis-gray' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-low-emphasis-gray-lozenge>

    <zelis-high-emphasis-yellow-lozenge
      *ngIf="lozengeType === 'high-emphasis-yellow' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-high-emphasis-yellow-lozenge>

    <p *ngIf="metadata" class="metadata">{{ metadata }}</p>

    <mat-icon
      *ngIf="rightIconName"
      [svgIcon]="rightIconName"
      [ngClass]="rightIconClass"
      #tooltip="matTooltip"
      [matTooltip]="rightIconTooltipText"
      [matTooltipPosition]="rightIconTooltipPosition"
      [matTooltipDisabled]="!rightIconTooltipText"
    ></mat-icon>
    <ng-container *ngTemplateOutlet="closeButton"></ng-container>
  </div>
</mat-list-option>

<a
  [ngClass]="linkClass"
  *ngIf="navigationList"
  mat-list-item
  tabindex="0"
  [id]="id"
  (click)="onNavListItemClick($event)"
  (keyup.enter)="onNavListItemClick($event)"
>
  <mat-icon
    matListIcon
    *ngIf="leftIconName"
    [svgIcon]="leftIconName"
  ></mat-icon>

  <zelis-avatar
    matListAvatar
    *ngIf="avatarSrc"
    [src]="avatarSrc"
    [placeholderIcon]="avatarPlaceholderIcon"
    size="small"
  ></zelis-avatar>

  <p
    matLine
    class="text-base text-palette-system-blue-500"
    [zelisHighlight]="highlightTerm"
    data-cy="list-item-primary-text"
    [innerHTML]="primaryText"
  ></p>

  <p
    *ngIf="secondaryText"
    matLine
    class="secondary-text"
    data-cy="list-item-secondary-text"
    [innerHTML]="secondaryText"
  ></p>

  <div class="flex items-center ml-4 space-x-4">
    <zelis-theme-lozenge
      *ngIf="
        lozengeType === 'theme' &&
        lozengeColor &&
        lozengeText &&
        lozengeEmphasis
      "
      [color]="lozengeColor"
      [emphasis]="lozengeEmphasis"
      [type]="lozengeType"
      [classes]="lozengeClasses"
      size="medium"
    >
      {{ lozengeText }}
    </zelis-theme-lozenge>

    <zelis-low-emphasis-gray-lozenge
      *ngIf="lozengeType === 'low-emphasis-gray' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-low-emphasis-gray-lozenge>

    <zelis-high-emphasis-yellow-lozenge
      *ngIf="lozengeType === 'high-emphasis-yellow' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-high-emphasis-yellow-lozenge>

    <p *ngIf="metadata" class="metadata">{{ metadata }}</p>

    <mat-icon
      *ngIf="rightIconName"
      [svgIcon]="rightIconName"
      [ngClass]="rightIconClass"
      #tooltip="matTooltip"
      [matTooltip]="rightIconTooltipText"
      [matTooltipPosition]="rightIconTooltipPosition"
      [matTooltipDisabled]="!rightIconTooltipText"
    ></mat-icon>
    <ng-container *ngTemplateOutlet="closeButton"></ng-container>
  </div>
</a>

<mat-list-item *ngIf="!selectionList && !navigationList" [id]="id">
  <mat-icon
    matListIcon
    *ngIf="leftIconName"
    [svgIcon]="leftIconName"
    [attr.aria-label]="ariaLabel"
    aria-hidden="false"
  ></mat-icon>

  <zelis-avatar
    matListAvatar
    *ngIf="avatarSrc"
    [src]="avatarSrc"
    [placeholderIcon]="avatarPlaceholderIcon"
    [isProviderAvatar]="isProviderAvatar"
    [ariaLabel]="ariaLabel"
    size="small"
  ></zelis-avatar>

  <p
    matLine
    class="text-base"
    [zelisHighlight]="highlightTerm"
    data-cy="list-item-primary-text"
    [innerHTML]="primaryText"
  ></p>

  <p
    *ngIf="secondaryText"
    matLine
    class="secondary-text"
    data-cy="list-item-secondary-text"
    [innerHTML]="secondaryText"
  ></p>

  <div class="flex items-center ml-4 space-x-4">
    <zelis-theme-lozenge
      *ngIf="
        lozengeType === 'theme' &&
        lozengeColor &&
        lozengeText &&
        lozengeEmphasis
      "
      [color]="lozengeColor"
      [emphasis]="lozengeEmphasis"
      [type]="lozengeType"
      [classes]="lozengeClasses"
      size="medium"
    >
      {{ lozengeText }}
    </zelis-theme-lozenge>

    <zelis-low-emphasis-gray-lozenge
      *ngIf="lozengeType === 'low-emphasis-gray' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-low-emphasis-gray-lozenge>

    <zelis-high-emphasis-yellow-lozenge
      *ngIf="lozengeType === 'high-emphasis-yellow' && lozengeText"
      size="medium"
      [type]="lozengeType"
    >
      {{ lozengeText }}
    </zelis-high-emphasis-yellow-lozenge>

    <p *ngIf="metadata" class="metadata">{{ metadata }}</p>

    <mat-icon
      *ngIf="rightIconName"
      [svgIcon]="rightIconName"
      [ngClass]="rightIconClass"
      #tooltip="matTooltip"
      [matTooltip]="rightIconTooltipText"
      [matTooltipPosition]="rightIconTooltipPosition"
      [matTooltipDisabled]="!rightIconTooltipText"
    ></mat-icon>
    <ng-container *ngTemplateOutlet="closeButton"></ng-container>
  </div>
</mat-list-item>

<ng-template #closeButton>
  <button
    *ngIf="dismissible"
    mat-icon-button
    class="h-6 w-6 text-sm leading-4"
    (click)="onClose($event)"
    aria-label="close"
    data-cy="list-item-close-button"
  >
    <mat-icon class="h-4 w-4" svgIcon="dls-times-plain-light"></mat-icon>
  </button>
</ng-template>
