import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavButtonComponent } from './nav-button/nav-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [CommonModule, MatButtonModule, DlsIconModule],
  declarations: [NavButtonComponent],
  exports: [NavButtonComponent, MatButtonModule],
})
export class DlsNavButtonModule {}
