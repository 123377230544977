<div class="text-palette-m3-system-gray-10 px-6 md:p-0 flex sm:flex-col sm:items-center">
  <div>
    <div class="header-content mb-16 text-center">
      <h3 class="heading-3 font-bold">
        {{ 'network_selection_wizard_modify_selections_header' | translate }}
      </h3>
      <div class="text-base mt-2 p-0">
        {{ 'network_selection_wizard_modify_selections_description' | translate }}
      </div>
    </div>

    <mat-selection-list [multiple]="false">
      <zelis-list-item
        [ariaLabel]="'modify-location'"
        [primaryText]="'network_selection_wizard_modify_selections_location' | translate"
        [secondaryText]="previousSelections.location"
        [selected]="selectedOption === 'location'"
        (click)="handleSelect('location')"
        (keydown.enter)="handleSelect('location')"
      >
      </zelis-list-item>
      <mat-divider class="middle"></mat-divider>
      <zelis-list-item
        [ariaLabel]="'modify-plan'"
        [primaryText]="'network_selection_wizard_modify_selections_plan' | translate"
        [secondaryText]="previousSelections.plan"
        [selected]="selectedOption === 'plan'"
        (click)="handleSelect('plan')"
        (keydown.enter)="handleSelect('plan')"
      >
      </zelis-list-item>
      <mat-divider class="middle"></mat-divider>
      <zelis-list-item
        [ariaLabel]="'modify-location-and-plan'"
        [primaryText]="'network_selection_wizard_modify_selections_both' | translate"
        [selected]="selectedOption === 'both'"
        (click)="handleSelect('both')"
        (keydown.enter)="handleSelect('both')"
      >
      </zelis-list-item>
    </mat-selection-list>

    <div class="mt-10 flex justify-end">
      <div class="w-[174px]">
        <zelis-button
          fullWidth="true"
          size="medium"
          color="system-blue"
          variant="flat"
          (click)="onContinue()"
          [disabled]="!selectedOption"
        >
          {{ 'app_global_continue' | translate }}
        </zelis-button>
      </div>
    </div>
  </div>
</div>
