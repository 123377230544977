import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CarouselItemDirective } from './carousel.item.directive';

@NgModule({
  exports: [CarouselComponent, CarouselItemDirective],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule],
  declarations: [CarouselComponent, CarouselItemDirective],
})
export class CarouselModule {}
