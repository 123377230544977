import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GatedEntryOverlayAlphaPrefixComponent } from './gated-entry-overlay-alpha-prefix.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MemberCardModule } from '@zelis/platform-ui-components';
import { TokenizedInputModule } from '@zelis/platform-ui-components';
import { ReactiveFormsModule } from '@angular/forms';
import { UnfocusModule } from '../../../directives/unfocus/unfocus.module';
import { GlobalLocationSelectModule } from '../../global-header/location/global-location-select.module';

@NgModule({
  declarations: [GatedEntryOverlayAlphaPrefixComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MemberCardModule,
    TokenizedInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    UnfocusModule,
    GlobalLocationSelectModule,
  ],
  exports: [GatedEntryOverlayAlphaPrefixComponent],
})
export class GatedEntryOverlayAlphaPrefixModule {}
