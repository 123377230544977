import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AZListLegendComponent } from './a-z-list-legend.component';

@NgModule({
  exports: [AZListLegendComponent],
  imports: [CommonModule, FlexLayoutModule],
  declarations: [AZListLegendComponent],
})
export class AZListLegendModule {}
