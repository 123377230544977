import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GatedEntryOverlayHomeComponent } from './gated-entry-overlay-home.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GtmModule } from '../../../directives/gtm/gtm.module';

@NgModule({
  declarations: [GatedEntryOverlayHomeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    GtmModule,
  ],
  exports: [GatedEntryOverlayHomeComponent],
})
export class GatedEntryOverlayHomeModule {}
