import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { AuthService } from '@services/auth.service';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { AuthStatus } from '@interfaces/auth-status.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { ActivatedRoute, Params } from '@angular/router';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';

@Injectable({
  providedIn: 'root',
})
export class UuxService {
  public uuxNetworkChangeTooltip: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private isInitialPageLoad: boolean = true;

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private criticalParamsService: CriticalParamsService
  ) {}

  /**
   * Get the uux config if member is not authenticated and a corp_code exists.
   * @returns Observable<boolean>
   */
  public getUuxConfig(): Observable<boolean> {
    return this.route.queryParams.pipe(
      first(),
      switchMap((params: Params) => this.shouldRequestConfig(params)),
      switchMap((requestConfig: boolean) => {
        if (requestConfig) {
          return this.requestUuxConfig();
        }
        return of(false);
      })
    );
  }

  /**
   * Get alpha_prefix_enabled if member is not authenticated and a corp_code exists.
   * @returns Observable<boolean>
   */
  public getAlphaPrefixConfig(): Observable<boolean> {
    return this.route.queryParams.pipe(
      first(),
      switchMap((params: Params) => this.shouldRequestConfig(params)),
      switchMap((requestConfig: boolean) => {
        if (requestConfig) {
          return this.requestAlphaPrefixConfig();
        }
        return of(false);
      })
    );
  }

  public updateUuxNetworkChangeTooltip(showValue: boolean) {
    this.uuxNetworkChangeTooltip.next(showValue);
  }

  public getPlans(params: any): Observable<UuxNetwork[]> {
    const url = `/api/marketing_plans.json`;
    const httpParams = new HttpParams({ fromObject: params });

    return this.http
      .get(url, { withCredentials: true, params: httpParams })
      .pipe(
        catchError(() => of(null)),
        filter((response: any) => response?.marketing_plans),
        map((response: any) =>
          response.marketing_plans.map((plan) => new UuxNetwork(plan))
        )
      );
  }

  public getPlansAndCi(params: any): Observable<[string, UuxNetwork[]]> {
    return combineLatest([this.getCi(), this.getPlans(params)]);
  }

  private getCi(): Observable<string> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      filter((criticalParams) => !!criticalParams.ci),
      map((cParams) => cParams.ci),
      distinctUntilChanged()
    );
  }

  private requestAlphaPrefixConfig(): Observable<boolean> {
    return this.settingsService.getSetting('alpha_prefix_enabled').pipe(
      first(),
      switchMap((enabled) => of(!!enabled))
    );
  }

  private requestUuxConfig(): Observable<boolean> {
    return this.settingsService.getSetting('unauthenticated_entry').pipe(
      first(),
      switchMap((enabled) => of(!!enabled))
    );
  }

  private shouldRequestConfig(params: Params): Observable<boolean> {
    if (!!params.corp_code) {
      return this.authService.authStatus.pipe(
        first((authStatus) => !!authStatus.resolved),
        switchMap((authStatus: AuthStatus) => {
          return of(!authStatus.auth_status);
        })
      );
    }
    return of(false);
  }

  /**
   * This flag indicates if the page is loaded for the first time.
   * If so, the "UUX Search Again" tooltip should not be displayed.
   */
  public get getInitialPageLoadState(): boolean {
    return this.isInitialPageLoad;
  }

  public set setInitialPageLoadState(state : boolean) {
    this.isInitialPageLoad = state;
  }

}
