import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UuxNetwork } from '@interfaces/uux-network.model';

@Component({
  selector: 'app-uux-home',
  templateUrl: './uux-home.component.html',
  styleUrls: ['./uux-home.component.scss'],
})
export class UuxHomeComponent {
  @Input() public selectedNetwork: UuxNetwork;
  @Input() public showHelpScreen: boolean = false;
  @Input() public uuxNetworks: UuxNetwork[];

  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
  @Output() public selectUuxNetwork: EventEmitter<UuxNetwork> =
    new EventEmitter();
}
