import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MobileAutosuggestOverlayComponent } from './mobile-autosuggest-overlay.component';
import { GlobalHeaderAutosuggestModule } from '../global-header/autosuggest/global-header-autosuggest.module';
import { GlobalLocationSelectModule } from '../global-header/location/global-location-select.module';

@NgModule({
  declarations: [MobileAutosuggestOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    GlobalHeaderAutosuggestModule,
    GlobalLocationSelectModule,
  ],
  providers: [],
  exports: [MobileAutosuggestOverlayComponent],
})
export class MobileAutosuggestOverlayModule {}
