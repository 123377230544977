export class SettingsLoginLinks {
  public default: string;
  public dynamic_params: any;
  public relay_url: string;
  public msa_relay_url: string;

  constructor(obj: SettingsLoginLinks) {
    this.default = obj.default || '';
    this.dynamic_params = obj.dynamic_params || {};
    this.relay_url = this.fixRelativeUrl(obj.relay_url || '');
    this.msa_relay_url = this.fixRelativeUrl(obj.msa_relay_url || '');
  }

  private fixRelativeUrl?(url: string): string {
    return url.replace(/^\.?\//g, window.location.origin);
  }
}
