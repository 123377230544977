<div class="members-container" data-cy="members-dropdown.members-container">
  <mat-form-field
    class="material-override no-padding"
    appearance="fill"
    [ngClass]="{
      'read-only no-arrow': members?.length <= 1,
      'full-width': fullWidth
    }"
    [attr.aria-label]="'home_member_select' | translate"
  >
    <mat-label class="for-label">
      {{ 'app_global_for' | translate }}
    </mat-label>
    <mat-select
      class="members-select"
      *ngIf="members && members.length"
      #selectRef
      [(ngModel)]="selected"
      (selectionChange)="handleSelect($event.value)"
      name="members"
    >
      <mat-option
        (click)="emitMemberSelection(member)"
        class="members-select-option"
        *ngFor="let member of members; trackBy: trackByFn; let i = index"
        [attr.data-cy]="'members-dropdown.members-select-option-' + i"
        [value]="member"
      >
        <span *ngIf="i === 0 && !member.full_name">{{
          'app_global_search_me_label' | translate
        }}</span>
        <span *ngIf="member.full_name">{{ member.full_name }}</span>
      </mat-option>
    </mat-select>

    <!-- Suppressed Members "For: Me" -->
    <mat-select
      class="members-select members-suppressed"
      *ngIf="!members || !members.length"
      #selectRef
      name="members"
      [value]="0"
    >
      <mat-option class="members-select-option" [value]="0">
        <span>
          {{ 'app_global_search_me_label' | translate }}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
