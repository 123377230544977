import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GlobalLanguageSelectComponent } from './global-language-select.component';
import { AccountsService } from '../../../services/accounts.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DlsNavButtonModule } from '@zelis/dls/nav-button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    DlsNavButtonModule
  ],
  providers: [AccountsService],
  declarations: [GlobalLanguageSelectComponent],
  exports: [GlobalLanguageSelectComponent],
})
export class GlobalLanguageSelectModule {}
