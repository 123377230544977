import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AlphaListComponent } from './alpha-list.component';
import {
  AZListLegendModule,
  EventPreventDefaultModule,
  EventStopPropagationModule,
} from '@zelis/platform-ui-components';
import { AlphaListItemComponent } from './alpha-list-item/alpha-list-item.component';
import { GtmModule } from '../../directives/gtm/gtm.module';
import { AlphaListMenuItemComponent } from './alpha-list-menu-item/alpha-list-menu-item.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DlsListItemModule } from '@zelis/dls/list-item';
import { DlsLozengeModule } from '@zelis/dls/lozenge';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AZListLegendModule,
    GtmModule,
    ScrollingModule,
    EventPreventDefaultModule,
    EventStopPropagationModule,
    DlsListItemModule,
    DlsLozengeModule,
  ],
  declarations: [
    AlphaListComponent,
    AlphaListItemComponent,
    AlphaListMenuItemComponent,
  ],
  exports: [AlphaListComponent],
  providers: [CurrencyPipe],
})
export class AlphaListModule {}
