import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerIconComponent } from './disclaimer-icon.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SnackBarModule } from '../snack-bar/snack-bar.module';

@NgModule({
  exports: [DisclaimerIconComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatSnackBarModule,
    SnackBarModule,
  ],
  declarations: [DisclaimerIconComponent],
})
export class DisclaimerIconModule {}
