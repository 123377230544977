import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PassiveChatComponent } from './passive-chat.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChatButtonModule } from '../chat-button/chat-button.module';

@NgModule({
  declarations: [PassiveChatComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    ChatButtonModule,
  ],
  exports: [PassiveChatComponent],
})
export class PassiveChatModule {}
