<mat-chip
  class="heading-6 border border-solid border-palette-m3-system-gray-85"
  [attr.aria-label]="ariaLabel"
  [color]="color"
  [selected]="selected"
  [disabled]="disabled"
  [removable]="removable"
  [selectable]="selectable"
  [ngClass]="{
    'border border-solid border-palette-m3-system-gray-85 white-background after:bg-transparent': whiteBackground,
    selected: selected,
    'pointer-events-none bg-palette-m3-system-gray-95 text-palette-m3-system-gray-70':
      disabled,
    'pl-2': leadingIcon,
  }"
  (removed)="removed.emit($event)"
  (selectionChange)="selectionChange.emit($event)"
  (click)="selected = !selected"
>
  <ng-template [ngIf]="leadingIcon">
    <mat-icon
      mat-chip-avatar
      [svgIcon]="leadingIcon"
      class="leading-icon mr-2"
    ></mat-icon>
  </ng-template>

  <ng-content></ng-content>

  <ng-template [ngIf]="trailingIcon">
    <mat-icon matChipTrailingIcon [svgIcon]="trailingIcon"></mat-icon>
  </ng-template>

  <ng-template [ngIf]="removable">
    <button matChipRemove>
      <mat-icon
        svgIcon="dls-times-plain-light"
        [ngClass]="{
          'text-palette-m3-system-blue-30': selected,
          'text-palette-m3-system-gray-70': disabled
        }"
      ></mat-icon>
    </button>
  </ng-template>
</mat-chip>
