<div class="msa-form-container text-center" *ngIf="settings?.form?.inputs">
  <zelis-card [disableHover]="true" [elevated]="true" class="msa-form-card">
    <mat-card-content>
      <div class="msa-header"  class="flex flex-col justify-center">
        <img
          mat-card-image
          class="logo-img object-scale-down max-h-10 w-auto max-w-full"
          [src]="(headerLogo | async)?.header?.primary_logo"
          alt="Logo"
        />
        <h5 class="mt-2">{{ 'msa_member_impersonate_form_heading' | translate }}</h5>
        <p class="my-3">{{ 'msa_member_impersonate_intro_text' | translate }}</p>
      </div>
      <div class="msa-important" *ngIf="formSubmitted">
        {{ 'msa_members_error' | translate }}
      </div>

      <div
        class="msa-inputs pad all-1x"
        *ngFor="let input of settings.form.inputs; trackBy: trackByFn"
        (change)="disableSubmit()"
      >
        <mat-form-field
          floatLabel="never"
          class="msa-input no-outline"
          [class.no-border]="input.type !== 'date'"
        >
          <label *ngIf="input.type !== 'select'" [attr.for]="input.title">
            {{ input.title | translate }}
          </label>
          <div class="msa-hint">{{ input.hint | translate }}</div>

          <span *ngIf="input.type === 'text'">
            <input
              matInput
              [(ngModel)]="input.model"
              [attr.name]="input.title"
              [attr.minLength]="input.minLength"
              [attr.maxlength]="input.maxLength"
              [ngClass]="{ 'has-error': shouldShowTextError(input) }"
              data-cy="msa.form.text-input"
              (input)="
                input.model.length === input.minLength ? disableSubmit() : ''
              "
              required
            />
            <div *ngIf="shouldShowTextError(input)" class="msa-important">
              {{ 'msa_member_impersonate_length_error' | translate }}
            </div>
          </span>

          <span *ngIf="input.type === 'date'">
            <input
              matInput
              type="date"
              data-cy="msa.form.date-input"
              required
              [(ngModel)]="input.model"
              [max]="maxDate"
              [min]="minDate"
              (input)="disableSubmit()"
            />
            <div *ngIf="shouldShowSelectError(input.model)" class="msa-important">
              {{ 'msa_member_impersonate_length_error' | translate }}
            </div>
          </span>

          <span
            class="msa-select"
            *ngIf="input.type === 'select' && input.options"
          >
            <mat-form-field>
              <mat-select
                [placeholder]="input.title | translate"
                [(value)]="input.model"
                data-cy="msa.form.select"
                (selectionChange)="disableSubmit()"
              >
                <mat-option
                  *ngFor="let option of input.options; trackBy: trackByFn"
                  [value]="option.id"
                >
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="shouldShowSelectError(input.model)" class="msa-important">
              {{ 'msa_member_impersonate_select_error' | translate }}
            </div>
          </span>
        </mat-form-field>
      </div>

      <div class="msa-submit">
        <button
          class="platform-button button-small margin right-1x"
          data-cy="msa.form.submit-button"
          mat-stroked-button
          color="accent"
          (click)="postForm()"
          [disabled]="disableSubmitBtn"
        >
          {{ 'app_global_submit' | translate }}
        </button>
        <button
          class="platform-button button-small"
          mat-stroked-button
          color="accent"
          (click)="clearForm()"
          data-cy="msa.form.clear"
        >
          {{ 'msa_member_impersonate_button_clear' | translate }}
        </button>
      </div>

      <div class="msa-logout">
        <p class="my-3">{{ 'msa_member_impersonate_session_logout_text' | translate }}</p>
        <button
          (click)="logout()"
          class="platform-button button-small"
          mat-stroked-button
          color="accent"
        >
          <a>{{ 'msa_banner_logoutSession_button' | translate }}</a>
        </button>
      </div>
    </mat-card-content>
  </zelis-card>
</div>
