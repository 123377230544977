import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { HierarchyListNestedOptionsComponent } from './hierarchy-list-nested-options.component';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [HierarchyListNestedOptionsComponent],
  imports: [CommonModule, TranslateModule, FlexLayoutModule, MatIconModule, DlsIconModule],
  exports: [HierarchyListNestedOptionsComponent],
})
export class HierarchyListNestedOptionsModule {}
