import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxAlphaPrefixComponent } from './uux-alpha-prefix.component';
import { TokenizedInputModule } from '@zelis/platform-ui-components';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MemberCardModule } from '@zelis/platform-ui-components';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GtmModule } from '../../../directives/gtm/gtm.module';
import { DlsButtonModule } from '@zelis/dls/button';

@NgModule({
  declarations: [UuxAlphaPrefixComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TokenizedInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    MatMenuModule,
    MemberCardModule,
    ReactiveFormsModule,
    TranslateModule,
    GtmModule,
    DlsButtonModule,
  ],
  exports: [UuxAlphaPrefixComponent],
})
export class UuxAlphaPrefixModule {}
