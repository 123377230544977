<div class="pt-5" *ngIf="fullScreenState$ | async as fullScreenState">
  <div
    [class.pl-6]="fullScreenState.isFullScreen && buttons.length"
    class="pb-2"
  >
    <ng-content></ng-content>
  </div>
  <mat-dialog-actions align="end" *ngIf="buttons.length > 0">
    <ng-container *ngFor="let button of buttons; let i = index">
      <ng-template [ngIf]="fullScreenState.isFullScreen">
        <button
          *ngIf="button.isCloseButton && !button.hasInitialFocus"
          zelisButton
          mat-flat-button
          [color]="color"
          mat-dialog-close
          [fullWidth]="true"
          [disabled]="!!button.isDisabled"
          size="large"
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="button.hasInitialFocus && !button.isCloseButton"
          zelisButton
          mat-flat-button
          [color]="color"
          cdkFocusInitial
          [fullWidth]="true"
          [disabled]="!!button.isDisabled"
          size="large"
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="button.hasInitialFocus && button.isCloseButton"
          zelisButton
          mat-flat-button
          [color]="color"
          mat-dialog-close
          cdkFocusInitial
          [fullWidth]="true"
          [disabled]="!!button.isDisabled"
          size="large"
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="!button.hasInitialFocus && !button.isCloseButton"
          zelisButton
          mat-flat-button
          [color]="color"
          [fullWidth]="true"
          [disabled]="!!button.isDisabled"
          size="large"
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
      </ng-template>

      <ng-template [ngIf]="!fullScreenState.isFullScreen">
        <button
          *ngIf="button.isCloseButton && !button.hasInitialFocus"
          zelisButton
          mat-button
          [color]="color"
          [disabled]="!!button.isDisabled"
          mat-dialog-close
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="button.hasInitialFocus && !button.isCloseButton"
          zelisButton
          mat-button
          [color]="color"
          [disabled]="!!button.isDisabled"
          cdkFocusInitial
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="button.hasInitialFocus && button.isCloseButton"
          zelisButton
          mat-button
          [color]="color"
          [disabled]="!!button.isDisabled"
          mat-dialog-close
          cdkFocusInitial
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
        <button
          *ngIf="!button.hasInitialFocus && !button.isCloseButton"
          zelisButton
          mat-button
          [color]="color"
          [disabled]="!!button.isDisabled"
          (click)="handleButtonClick($event, i)"
        >
          <mat-icon
            *ngIf="button.icon"
            [svgIcon]="button.icon"
            zelisButtonIcon
          ></mat-icon>
          {{ button.content }}
        </button>
      </ng-template>
    </ng-container>
  </mat-dialog-actions>
</div>
