import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UuxComponent } from './uux.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxHeaderModule } from './uux-header/uux-header.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { UuxHomeModule } from './uux-home/uux-home.module';
import { UuxHelpFindPlanModule } from './uux-help-find-plan/uux-help-find-plan.module';
import { UuxContainerModule } from './uux-container/uux-container.module';
import { UuxAlphaPrefixModule } from './uux-alpha-prefix/uux-alpha-prefix.module';
import { UuxQuestionnaireModule } from './uux-questionnaire/uux-questionnaire.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UuxQuestionnaireOptionsModule } from './uux-questionnaire-options/uux-questionnaire-options.module';
import { UuxPlanNetworkListModule } from './uux-plan-network-list/uux-plan-network-list.module';
import { FixedTooltipModule } from '../fixed-tooltip/fixed-tooltip.module';
import { GtmModule } from '../../directives/gtm/gtm.module';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    UuxHeaderModule,
    UuxHomeModule,
    UuxHelpFindPlanModule,
    UuxContainerModule,
    UuxAlphaPrefixModule,
    UuxQuestionnaireModule,
    UuxQuestionnaireOptionsModule,
    UuxPlanNetworkListModule,
    FixedTooltipModule,
    GtmModule,
    DlsIconModule
  ],
  declarations: [UuxComponent],
  exports: [UuxComponent],
})
export class UuxModule {}
