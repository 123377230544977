import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderComponent } from './global-header.component';
import { MobileHamburgerMenuModule } from '../mobile-hamburger-menu/mobile-hamburger-menu.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageContainerModule } from '@zelis/platform-ui-components';
import { SkipLinksModule } from '../skip-link/skip-links.module';
import { GlobalLanguageSelectModule } from './language/global-language-select.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { GlobalHeaderAutosuggestModule } from './autosuggest/global-header-autosuggest.module';
import { GlobalNetworkDropdownModule } from './network/global-network-dropdown.module';
import { GlobalLocationSelectModule } from './location/global-location-select.module';
import { MobileActionButtonsModule } from '../mobile-action-buttons';
import { UuxModule } from '../uux/uux.module';
import { GuidedSearchTriggerModule } from '@components/guided-search/guided-search-trigger/guided-search-trigger.module';
import { DlsButtonModule } from '@zelis/dls/button';
import { DlsNavButtonModule } from '@zelis/dls/nav-button';
import { SaveForPrintModule } from '@components/save-for-print/save-for-print.module';

@NgModule({
  declarations: [GlobalHeaderComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    PageContainerModule,
    SkipLinksModule,
    GlobalLanguageSelectModule,
    MatButtonModule,
    MatMenuModule,
    GlobalHeaderAutosuggestModule,
    GlobalNetworkDropdownModule,
    GlobalLocationSelectModule,
    MobileActionButtonsModule,
    MobileHamburgerMenuModule,
    MatIconModule,
    UuxModule,
    GuidedSearchTriggerModule,
    DlsButtonModule,
    DlsNavButtonModule,
    SaveForPrintModule,
  ],
  providers: [],
  exports: [GlobalHeaderComponent],
})
export class GlobalHeaderModule {}
