import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatLegacyChipEvent as MatChipEvent,
  MatLegacyChipSelectionChange as MatChipSelectionChange,
} from '@angular/material/legacy-chips';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChipComponent {
  @HostBinding() class = 'zelis-dls';

  @Input()
  @coerceBoolean
  selected = false;
  @Input()
  @coerceBoolean
  disabled = false;
  @Input()
  @coerceBoolean
  removable = false;
  @Input()
  @coerceBoolean
  selectable!: boolean;
  @Input()
  @coerceBoolean
  whiteBackground = false;
  @Input() leadingIcon = '';
  @Input() trailingIcon = '';
  @Input() ariaLabel?: string;
  @Input() color = 'primary';

  @Output() removed = new EventEmitter<MatChipEvent>();
  @Output() selectionChange = new EventEmitter<MatChipSelectionChange>();
}
