import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { SearchFilters } from '../+search/classes/search-filters.class';
import { MobileAutosuggestOverlayComponent } from '../mobile-autosuggest-overlay';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchFilterOption } from '@interfaces/search-filter-option.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { HierarchyListComponent } from '../hierarchy-list/hierarchy-list.component';
import { HierarchyListRefineData } from '@interfaces/hierarchy-list-refine-data.interface';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UuxService } from '@services/uux/uux.service';
import { TranslateService } from '@ngx-translate/core';
import { SortConfig } from '@interfaces/sort-config.model';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';

@Component({
  selector: 'app-mobile-action-buttons',
  templateUrl: './mobile-action-buttons.component.html',
  styleUrls: ['./mobile-action-buttons.component.scss'],
})
export class MobileActionButtonsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() types: any;
  @Input() incented: boolean;
  @Input() specialtySearchData: {
    facets: SearchFilterOption[];
    params: any;
    excludeSortOptions: string[];
  };
  @Input() public searchParamType: SearchParamType;
  @Input() public isSpecialtySearch: boolean;
  @Input() public radiusFilterConfig: SearchFilter;
  @Input() public searchTerm: string;
  @Input() public sortConfig: SortConfig;

  @Output() public openUux: EventEmitter<void> = new EventEmitter();
  @Output() public selectFilter: EventEmitter<any> = new EventEmitter();
  @Output() public selectRadius: EventEmitter<any> = new EventEmitter();
  @Output() public selectSort: EventEmitter<SearchSortOption> =
    new EventEmitter();

  public actionTypes: string[];
  public showAction: boolean = false;
  public currentActive: string = '';

  private titles = {
    network: 'app_global_network',
    location: 'app_global_location',
    filter: 'app_global_filter',
    refine: 'app_global_refine',
  };
  private configuredDropdown: SearchFilter[];
  private configuredNestedDropdown: SearchFilter[];
  private configuredCheckbox: SearchFilter[];
  private subscriptions: SubscriptionManager = new SubscriptionManager();
  private uuxEnabled: boolean = false;

  constructor(
    public locationAutosuggest: LocationAutosuggest,
    public searchFilters: SearchFilters,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private uuxService: UuxService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!this.isSpecialtySearch) {
      this.subscribeToFilters();
    }
    this.uuxService
      .getUuxConfig()
      .subscribe((enabled) => (this.uuxEnabled = enabled));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { specialtySearchData, types } = changes;
    this.onTypesChange(types);
    if (this.isSpecialtySearch) {
      this.onParamsChange(specialtySearchData);
    }
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public getTitle(type: string): string {
    return this.titles[type];
  }

  public openMobileAutosuggest() {
    this.dialog.open(MobileAutosuggestOverlayComponent, {
      backdropClass: 'fullscreen-overlay-autosuggest',
      panelClass: 'fullscreen-overlay',
      height: '100%',
      data: { locationSelected: true },
    });
  }

  public openMobileMenu(type: string): void {
    let children;
    let title;

    switch (type) {
      case 'location':
        this.openMobileAutosuggest();
        return;
      case 'network':
        if (this.uuxEnabled) {
          this.openUux.emit();
          return;
        }
        title = this.titles.network;
        break;
      case 'filter':
        title = this.titles.filter;
        children = this.isSpecialtySearch
          ? this.convertSpecialtySearchFacets()
          : this.getMobileFiltersData();
        break;
      case 'refine':
        title = this.titles.refine;
        children = this.isSpecialtySearch
          ? this.convertSpecialtySearchSort()
          : this.getMobileRefineData();
        break;
    }

    this.bottomSheet.open(HierarchyListComponent, {
      panelClass: 'full-screen-sheet',
      data: {
        type: type,
        title: title,
        children: children,
        incented: this.incented,
        isSpecialtySearch: this.isSpecialtySearch,
      },
    });

    this.subscriptions.add(
      this.bottomSheet._openedBottomSheetRef
        .afterDismissed()
        .subscribe((event) => {
          if (event) {
            const { selection, category, clear } = event;
            this.clearFilters(clear);
            this.onSearchFilterSelection(selection, category);
          }
        })
    );
  }

  public onSearchFilterSelection(selection: any, category: string): void {
    switch (category) {
      case 'refineSortBy':
        if (selection.query !== this.sortConfig.selected.query) {
          this.selectSort.emit(selection);
        }
        break;
      case 'refine':
        this.selectRadius.emit({ radius: selection.value });
        break;
      default:
        if (this.isSpecialtySearch) {
          if (
            selection.value !== this.specialtySearchData.params[selection.type]
          ) {
            this.selectFilter.emit(selection);
          }
          return;
        }
        this.selectFilter.emit(selection);
        this.searchFilters.areFiltersSelected.next(true);
    }
  }

  private clearFilters(clear: boolean): void {
    if (clear) {
      this.searchFilters.areFiltersSelected.next(false);
      this.searchFilters.getResetFilters(this.searchParamType);
    }
  }

  private convertSpecialtySearchSort(): {
    sort: SortConfig;
    searchParamType: string;
  } {
    const { excludeSortOptions } = this.specialtySearchData;
    const filteredOptions = this.sortConfig.search_specialty_id.filter(
      (option: SearchSortOption) => {
        return !excludeSortOptions.includes(option.translation);
      }
    );
    this.sortConfig.search_specialty_id = filteredOptions;
    return { sort: this.sortConfig, searchParamType: this.searchParamType };
  }

  private convertSpecialtySearchFacets(): SearchFilter[] {
    const { facets, params } = this.specialtySearchData;
    facets[0].name = `${this.translate.instant('app_global_all')} ${
      this.searchTerm
    }`;
    facets[0].value = null;
    const filter = new SearchFilter({ name: this.searchTerm, options: facets });
    filter.selected = params.expertise_codes || params.field_specialty_ids;
    return [filter];
  }

  private getMobileFiltersData(): SearchFilter[] {
    const filters = this.configuredDropdown;
    const configuredNestedDropdown = this.configuredNestedDropdown;
    const moreFilters =
      (configuredNestedDropdown &&
        configuredNestedDropdown[0] &&
        configuredNestedDropdown[0].items) ||
      [];
    return [...filters, ...moreFilters];
  }

  private getMobileRefineData(): HierarchyListRefineData {
    const checkbox = this.configuredCheckbox;
    const radius = this.radiusFilterConfig;
    const sort = this.searchFilters.getSort();
    const searchParamType = this.searchParamType;
    const searchTerm = this.searchTerm;

    return { checkbox, radius, sort, searchParamType, searchTerm };
  }

  private filterActionTypes(types: string): void {
    this.actionTypes = types.split(',').filter((item) => this.titles[item]);
  }

  private subscribeToFilters(): void {
    const sub = this.getFilters().subscribe((filters) => {
      this.configuredDropdown = filters.dropdown;
      this.configuredNestedDropdown = filters.nested;
      this.configuredCheckbox = filters.checkbox;
    });
    this.subscriptions.add(sub);
  }

  private getFilters(): Observable<{
    dropdown: SearchFilter[];
    nested: SearchFilter[];
    checkbox: SearchFilter[];
  }> {
    return combineLatest([
      this.searchFilters.configuredDropdown,
      this.searchFilters.configuredNestedDropdown,
      this.searchFilters.configuredCheckbox,
    ]).pipe(
      map(([dropdown, nested, checkbox]) => ({ dropdown, nested, checkbox }))
    );
  }

  private onParamsChange(specialtySearchData: SimpleChange): void {
    if (specialtySearchData) {
      const { currentValue, firstChange, previousValue } = specialtySearchData;
      if (
        firstChange ||
        (previousValue && previousValue.params !== currentValue.params)
      ) {
        const { expertise_codes, field_specialty_ids } = currentValue.params;
        this.searchFilters.areFiltersSelected.next(
          expertise_codes || field_specialty_ids
        );
      }
    }
  }

  private onTypesChange(types: SimpleChange): void {
    if (types && types.previousValue !== types.currentValue) {
      this.filterActionTypes(types.currentValue);
    }
  }
}
