import { Injectable } from '@angular/core';
import { RouteUtilities } from '@utilities/route.utilities';
import { PcpSettings } from '@interfaces/pcp-settings.model';
import { WindowService } from '@services/window.service';
import { AppParamsService } from '@services/app.params.service';
import { HnnyPcpService } from './hnny/pcp.service';
import { PcpData } from '@interfaces/pcp-data.interface';
import { lightFormat } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '@interfaces/app-config.model';
import { each, find, indexOf } from 'lodash';

const PCP_ID_TYPE_CODES = ['ID#', 'PCP', 'PID', 'JCD'];

@Injectable({
  providedIn: 'root',
})
export class PcpService {
  public routeUtilities = new RouteUtilities();
  public storedGetParams: any;
  public currentEligibilityDate: any;
  public futureEligibilityDate: any;
  public selectedPcpID: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    public windowService: WindowService,
    private appParams: AppParamsService
  ) {
    this.storedGetParams = this.storeGetParams();
    this.currentEligibilityDate = this.convertDateToTimestamp(
      this.storedGetParams['selection_eligibility_date'] || ''
    );
    this.futureEligibilityDate = this.setFutureEligibilityDate(
      this.storedGetParams['selection_interval']
    );
  }

  public isPcp(pcpIdentifier: string, provider: any): boolean {
    // If the pcp_identifier setting is set for an account,
    // check if that identifier is in is_pcp_for_accounts array
    if (pcpIdentifier) {
      return this.isPcpByAccount(pcpIdentifier, provider);
    }

    // Otherwise check API is_pcp field
    return this.isPcpByApi(provider && provider.is_pcp);
  }

  public isPcpByAccount(pcpIdentifier: string, provider: any): boolean {
    return !!(indexOf(provider.is_pcp_for_accounts, pcpIdentifier) > -1);
  }

  public isPcpByApi(isPcp: boolean): boolean {
    return isPcp;
  }

  public isPcpInIdentifiers(identifiers: any, pcpId: string): boolean {
    const isSelectedPCP = !!identifiers.find(
      (identifier) =>
        PCP_ID_TYPE_CODES.indexOf(identifier.type_code) > -1 &&
        identifier.value === pcpId
    );

    if (isSelectedPCP) {
      if (this.selectedPcpID.getValue() !== pcpId) {
        this.selectedPcpID.next(pcpId);
      }
      return true;
    }
    return false;
  }

  public isLinkbackUrlSet(settings: PcpSettings): boolean {
    return !!(
      this.storedGetParams.linkback_url ||
      this.appParams.params.linkback_url ||
      (settings && settings.linkback_url)
    );
  }

  public buildSelectionLink(
    provider: any,
    identifiers: any,
    settings: PcpSettings,
    appConfig: AppConfig
  ): string {
    let link = this.getLinkbackUrl(settings);

    // Add dynamic settings-driven map of provider properties
    each(settings.submit_params, (value, key) => {
      link = this.addGetParamsToLink(key, value, link, appConfig);

      if (this.shouldScrapeProvider(settings, key)) {
        link = this.addProviderValuesToLink(provider, value, key, link);
        link = this.addIdentifierValuesToLink(identifiers, value, key, link);
      }

      if (value === 'timestamp') {
        link = this.routeUtilities.appendToLink(link, 'timestamp', Date.now());
      }

      if (value === 'formatted_timestamp') {
        link = this.routeUtilities.appendToLink(
          link,
          key,
          lightFormat(new Date(), 'yyyy-MM-dd')
        );
      }

      if (value === 'ver_num') {
        link = this.routeUtilities.appendToLink(link, 'ver_num', 2);
      }
    });

    return link;
  }

  public isDateEligible(): boolean {
    let out = true;

    if (new Date().getTime() < this.currentEligibilityDate) {
      out = false;
    }

    return out;
  }

  public buildDeselectionLink(settings: PcpSettings): string {
    let link = this.getLinkbackUrl(settings);

    each(settings.submit_deselect_params, (value, key) => {
      if (value === 'trans_id') {
        value = this.storedGetParams[value];
      }
      link = this.routeUtilities.appendToLink(link, key, value);
    });

    return link;
  }

  public setEffectiveDateFromSso(pcpData: PcpData): void {
    if (pcpData && pcpData.effective_date) {
      this.storedGetParams.selection_eligibility_date = pcpData.effective_date;
      this.currentEligibilityDate = this.convertDateToTimestamp(pcpData.effective_date);
    }
  }

  public clientCannotSelect(appConfig: AppConfig, provider: any): boolean {
    switch (true) {
      case appConfig.isClient('hnny'):
        const hnnyPcpService = new HnnyPcpService();

        return hnnyPcpService.cannotSelect(
          provider,
          this.storedGetParams,
          this.routeUtilities.getParamsFromUrl()['ci']
        );

      default:
        return false;
    }
  }

  private storeGetParams(): any {
    let out = this.routeUtilities.getParamsFromString(
      this.windowService['location'].search
    );
    const savedGetParams =
      this.windowService['sessionStorage'].getItem('savedGetParams');

    if (savedGetParams) {
      out = {
        ...JSON.parse(savedGetParams),
        ...out,
      };
    }

    this.windowService['sessionStorage'].setItem(
      'savedGetParams',
      JSON.stringify(out)
    );

    return out;
  }

  private getLinkbackUrl(settings: PcpSettings): string {
    return (
      this.storedGetParams['linkback_url'] ||
      this.appParams.params.linkback_url ||
      (settings && settings.linkback_url) ||
      ''
    );
  }

  private shouldScrapeProvider(settings, key): boolean {
    return !!(
      (settings.only_scrape_get_params &&
        settings.only_scrape_get_params.indexOf(key) === -1) ||
      !settings.only_scrape_get_params
    );
  }

  private addGetParamsToLink(
    key: string,
    value: string,
    link: string,
    appConfig: AppConfig
  ): string {
    if (this.storedGetParams[key]) {
      link = this.routeUtilities.appendToLink(
        link,
        value,
        this.storedGetParams[key]
      );
    } else if (key === 'network_id') {
      link = this.routeUtilities.appendToLink(
        link,
        key,
        this.appParams.params.network_id
      );
    } else if (key === 'configuration') {
      link = this.addConfigurationParams(link, value, appConfig);
    }

    return link;
  }

  private addConfigurationParams(
    link: string,
    configObj: any,
    appConfig: AppConfig
  ): string {
    const env = appConfig.environment;
    Object.keys(configObj).forEach((key) => {
      const settingValue =
        configObj[key][env] || configObj[key]['default'] || false;
      if (settingValue) {
        link = this.routeUtilities.appendToLink(link, key, settingValue);
      }
    });
    return link;
  }

  private addProviderValuesToLink(
    provider: any,
    value: any,
    key: string,
    link: string
  ): string {
    if (provider[value]) {
      link = this.routeUtilities.appendToLink(link, key, provider[value]);
    }

    return link;
  }

  private addIdentifierValuesToLink(
    identifiers: any,
    value: any,
    key: string,
    link: string
  ): string {
    const foundIdentifier: any = find(identifiers, { type_code: value });
    if (foundIdentifier) {
      link = this.routeUtilities.appendToLink(link, key, foundIdentifier.value);
    }

    return link;
  }

  private convertDateToTimestamp(date): any {
    let formattedDate;
    let dateArray: string[];
    let testDate: string;

    if (date) {
      dateArray = date.split('-');
      if (dateArray[0].match('4d')) {
        testDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
      } else {
        testDate = date;
      }
      formattedDate = new Date(testDate).getTime();
    }
    if (isNaN(formattedDate)) {
      formattedDate = new Date(date + 'T12:00:00').getTime();
    }

    // If not a valid date, do not allow selection
    if (isNaN(formattedDate)) {
      formattedDate = new Date().getTime() + 1000;
    }

    return formattedDate;
  }

  private setFutureEligibilityDate(selectionInterval): number {
    if (!selectionInterval) {
      return null;
    }

    return new Date().setDate(
      new Date().getDate() + parseInt(selectionInterval, 10)
    );
  }
}
