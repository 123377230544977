<div
  [ngClass]="{
    'uux-container-global-header': globalHeader,
    'uux-container-home': !globalHeader
  }"
  data-cy="uux.home"
>
  <div
    mat-button
    class="dropdown-trigger ellipsis network-select"
    [matMenuTriggerFor]="uuxDropdown"
    [ngClass]="{
      toutable: toutEnabled && !toutDismissed,
      tout: tout && !toutDismissed
    }"
  >
    <div class="uux-label">{{ 'uux_dropdown_label' | translate }}</div>
    <span
      *ngIf="selectedNetwork"
      class="text-regular ellipsis"
      [innerHTML]="selectedNetwork.displayName | translate"
    ></span>
    <mat-icon svgIcon="dls-caret-down-solid" class="dropdown-arrow"></mat-icon>
  </div>
  <app-fixed-tooltip
    *ngIf="toutEnabled && !toutDismissed"
    (closeElement)="onTooltipClose()"
    [title]="'tout_network_title' | translate"
    [text]="'tout_network_text' | translate"
  >
  </app-fixed-tooltip>

  <mat-menu
    #uuxDropdown="matMenu"
    class="menu-standard"
    yPosition="below"
    [overlapTrigger]="false"
    (closed)="onDropdownClosed()"
  >
    <div (click)="$event.stopPropagation()">
      <app-uux-header
        [currentPage]="currentStep.page"
        [marketSegment]="marketSegment"
        [selectedState]="selectedState"
        (goBack)="onGoBack()"
        (closeMenu)="closeDropdown()"
      >
      </app-uux-header>

      <app-uux-container>
        <app-uux-home
          *ngIf="currentStep.page === 'home'"
          [@slideInOutAnimation]="currentStep.direction"
          [selectedNetwork]="selectedNetwork"
          [uuxNetworks]="uuxNetworks"
          [showHelpScreen]="alphaPrefixEnabled"
          (goToPage)="onGoToPage($event)"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
        >
        </app-uux-home>

        <app-uux-help-find-plan
          *ngIf="currentStep.page === 'help'"
          [@slideInOutAnimation]="currentStep.direction"
          [alphaPrefixEnabled]="alphaPrefixEnabled"
          (goToPage)="onGoToPage($event)"
        >
        </app-uux-help-find-plan>

        <app-uux-alpha-prefix
          *ngIf="currentStep.page === 'alpha'"
          [ppoPlan]="ppoPlan"
          (goBack)="onGoBack()"
          (goToPage)="onGoToPage($event)"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
          (setPrefix)="alphaPrefixCode = $event"
          [@slideInOutAnimation]="currentStep.direction"
        >
        </app-uux-alpha-prefix>

        <!-- this is the dropdown of networks -->
        <app-uux-plan-network-list
          *ngIf="currentStep.page === 'networks'"
          [selectedNetwork]="selectedNetwork"
          [marketSegment]="marketSegment"
          [@slideInOutAnimation]="currentStep.direction"
          (goToPage)="onGoToPage($event)"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
        >
        </app-uux-plan-network-list>

        <app-uux-questionnaire
          *ngIf="currentStep.page === 'questionnaire'"
          [@slideInOutAnimation]="currentStep.direction"
          (goToPage)="onGoToPage($event)"
          (setMarketSegment)="onSetMarketSegment($event)"
        >
        </app-uux-questionnaire>

        <app-uux-questionnaire-options
          *ngIf="currentStep.page === 'options'"
          [marketSegment]="marketSegment"
          [@slideInOutAnimation]="currentStep.direction"
          (setStatePlans)="uuxNetworks = $event"
          (goToPage)="onGoToPage($event)"
          (setState)="selectedState = $event"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
        >
        </app-uux-questionnaire-options>

        <app-uux-plan-network-list
          *ngIf="currentStep.page === 'plans'"
          [uuxNetworks]="uuxNetworks"
          [selectedNetwork]="selectedNetwork"
          [marketSegment]="marketSegment"
          [planListHeaderTranslationKey]="'uux_subheader_questionnaire_plans'"
          [@slideInOutAnimation]="currentStep.direction"
          (goToPage)="onGoToPage($event)"
          (selectPpoPlan)="ppoPlan = $event"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
        >
        </app-uux-plan-network-list>

        <app-uux-plan-network-list
          *ngIf="currentStep.page === 'matching'"
          [uuxNetworks]="uuxNetworks"
          [currentPage]="currentStep.page"
          [selectedNetwork]="selectedNetwork"
          (goToPage)="onGoToPage($event)"
          (selectUuxNetwork)="onSelectUuxNetwork($event)"
        >
        </app-uux-plan-network-list>
      </app-uux-container>
    </div>
  </mat-menu>
</div>
