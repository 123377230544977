import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { ChatMessageModule } from '../chat-message/chat-message.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { UnfocusModule } from '../../../directives/unfocus/unfocus.module';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    ChatMessageModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxAutoScrollModule,
    TranslateModule,
    UnfocusModule,
    DlsProgressSpinnerModule,
  ],
  declarations: [ChatComponent],
  exports: [ChatComponent],
})
export class ChatModule {}
