import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GlobalHeaderAutosuggestComponent } from './global-header-autosuggest.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { DlsFocusModule } from '@zelis/dls/focus';
import { DlsListItemModule } from '@zelis/dls/list-item';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { DlsChipModule } from '@zelis/dls/chip';
import { DlsSearchInputModule } from '@zelis/dls/search-input';
import { PendoModule } from '@directives/pendo/pendo.module';

@NgModule({
  declarations: [GlobalHeaderAutosuggestComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    DlsFocusModule,
    DlsListItemModule,
    DlsProgressSpinnerModule,
    DlsChipModule,
    DlsSearchInputModule,
    PendoModule
  ],
  providers: [NgModel],
  exports: [GlobalHeaderAutosuggestComponent],
})
export class GlobalHeaderAutosuggestModule {}
