<div class="uux-plan-network-list-container" data-cy="uux.plan-network-list">
  <div
    *ngIf="planListHeaderTranslationKey"
    class="pad top-4x bottom-2x x-2x"
    [ngClass]="{ 'text-center': !!marketSegment }"
    [innerHTML]="planListHeaderTranslationKey | translate"
  ></div>
  <zelis-progress-spinner
    class="flex justify-center"
    *ngIf="!uuxNetworks?.length"
  ></zelis-progress-spinner>

  <div *ngIf="uuxNetworks?.length">
    <button
      *ngFor="
        let network of uuxNetworks;
        trackBy: trackByDisplayName;
        let i = index
      "
      mat-menu-item
      (click)="onSelectUuxNetwork(network)"
      class="w-100 uux-network"
      [attr.data-cy]="'uux.network.' + i"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        fxFlex="grow"
        [ngClass]="{
          'text-bold': network.displayName === selectedNetwork?.displayName
        }"
        [innerHTML]="network.displayName | translate"
      ></div>
      <div fxFlex fxFlexAlign="end">
        <mat-icon
          svgIcon="dls-check-plain-light"
          class="text-black"
          *ngIf="network.displayName === selectedNetwork?.displayName"
        >
        </mat-icon>
      </div>
    </button>
  </div>

  <button
    *ngIf="currentPage === 'matching'"
    class="select-button link-blue"
    mat-menu-item
    (click)="selectAllPlans()"
    appGtm
    [gtmOnClick]="true"
    [gtmData]="{
      event: 'select-a-different-plan',
      label: selectedNetwork?.displayName
    }"
  >
    {{ 'uux_matching_button' | translate }}
  </button>
</div>
