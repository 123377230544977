<div class="text-palette-m3-system-gray-10 px-6 md:p-0 md:ml-[148px] max-w-[882px]">

  <div class="mb-10">
    <h3 class="heading-3 font-bold">
      {{pageHeading}}
    </h3>

    <div *ngIf="description" class="text-base mt-2 max-w-[582px]">
      {{description}}
    </div>
  </div>

  <mat-selection-list [multiple]="false" class="w-full">
    <mat-list-option
      class="h-fit text-palette-m3-system-gray-10"
      *ngFor="let plan of plans;  let last = last; let i = index"
      (click)="handleSelect(plan)"
      (keydown)="handleKeyDownSelect($event, plan)"
      [selected]="plans?.length === 1"
      [attr.data-cy]="'mat-list-option-' + i"
    >
      <mat-radio-button
        class="inline-flex"
        [aria-label]="plan.displayName"
        [value]="plan"
        [checked]="plan === selectedPlan || plans?.length === 1"
        matListIcon
      >
        <span class="invisible" [innerHTML]="plan.displayName"></span>
      </mat-radio-button>

      <ng-container *ngIf="breakpoints.isDesktop; else mobileLayout">
        <div class="flex justify-between items-center mt-4 mb-4">
          <div class="mr-1">
            <p class="text-medium text-base" [innerHTML]="plan.displayName"></p>
            <ng-container [ngTemplateOutlet]="planInfo" [ngTemplateOutletContext]="{ plan }"></ng-container>
          </div>
          <zelis-theme-lozenge
            *ngIf="plan.label"
            [emphasis]="'low'"
            [color]="'system-blue'"
            size="medium"
          >
            {{ plan.label.toUpperCase() }}
          </zelis-theme-lozenge>
        </div>
      </ng-container>
      <ng-template #mobileLayout>
        <div class="flex flex-col mt-4 mb-4">
          <p class="text-base" [innerHTML]="plan.displayName"></p>
          <zelis-theme-lozenge
            class="my-2"
            *ngIf="plan.label"
            [emphasis]="'low'"
            [color]="'system-blue'"
            size="medium"
          >
            {{ plan.label.toUpperCase() }}
          </zelis-theme-lozenge>
          <ng-container [ngTemplateOutlet]="planInfo" [ngTemplateOutletContext]="{ plan }"></ng-container>
        </div>
      </ng-template>
      <mat-divider *ngIf="!last" class="relative middle"></mat-divider>
    </mat-list-option>
  </mat-selection-list>

  <div class="mt-8 flex flex-col sm:items-end">
    <div class="sm:min-w-[240px]">
      <zelis-button
        fullWidth="true"
        size="medium"
        variant="flat"
        color="system-blue"
        (click)="handleSubmit()"
        [disabled]="!selectedPlan && plans?.length !== 1"
      >
        {{ 'network_selection_wizard_plan_list_cta' | translate }}
      </zelis-button>
    </div>
    <app-wizard-login-link class="text-center my-4"></app-wizard-login-link>
  </div>
</div>

<ng-template #planInfo let-plan="plan">
  <p
    *ngIf="plan.related_plans"
    class="text-palette-m3-system-gray-35 my-1"
    [innerHTML]="plan.related_plans"
  ></p>
  <p
    *ngIf="plan.description"
    [innerHTML]="plan.description"
  ></p>
</ng-template>
