<div class="alpha-list" ngClass.lt-md="mobile">
  <div class="filter" fxLayoutAlign="center">
    <mat-form-field
      class="full-width"
      appearance="fill"
      floatLabel="always"
    >
      <input
        class="focus:outline-none"
        data-cy="alpha-list-input"
        matInput
        #searchFilter
        autocomplete="off"
        [attr.aria-label]="'guided_search_filter' | translate"
        [placeholder]="'guided_search_filter' | translate"
        [formControl]="filterControl"
        (keydown)="$event.stopPropagation(); alphaListFocus($event)"
        (click)="$event.stopPropagation()"
      />
    </mat-form-field>
  </div>

  <div class="legend-container" *ngIf="showLegend && !breakpoints.isMobile">
    <div>{{ 'guided_search_jump_to' | translate }}</div>
    <app-a-z-list-legend
      data-cy="alpha-list.a-z-list-legend"
      [activeLetters]="activeLetters()"
      (click)="$event.stopPropagation()"
      (selectedLetter)="jumpTo($event)"
      (focusListItem)="focusFirstItem()"
    >
    </app-a-z-list-legend>
  </div>

  <div class="scroll-container" [style.height.px]="height">
    <cdk-virtual-scroll-viewport
      class="virtual-scroll-viewport"
      [itemSize]="80"
    >
      <mat-nav-list>
        <app-alpha-list-menu-item
          *cdkVirtualFor="
            let item of alphaListService.activeListFiltered;
            let i = index;
            trackBy: trackByFn
          "
          [attr.data-cy]="'alpha-list-menu-item-' + i"
          [item]="item"
          [index]="i"
          [carepathEnabled]="carepathEnabled"
          (selected)="selected.emit(item)"
        >
        </app-alpha-list-menu-item>
      </mat-nav-list>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
