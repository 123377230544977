import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxHelpFindPlanComponent } from './uux-help-find-plan.component';
import { GtmModule } from '../../../directives/gtm/gtm.module';
import { DlsIconModule } from '@zelis/dls/icons';


@NgModule({
  declarations: [UuxHelpFindPlanComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    GtmModule,
    DlsIconModule,
  ],
  exports: [UuxHelpFindPlanComponent],
})
export class UuxHelpFindPlanModule {}
