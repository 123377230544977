import { capitalizeFirstLetter } from '@zelis/platform-ui-components';

export class PreferredAddress {
  capitalizeFirstLetter = capitalizeFirstLetter;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
  phone_number: string;
  email_address: string;
  preference_type: 'address' | 'phone' | 'email';

  constructor(obj?: any) {
    this.address_line1 =
      obj && obj.address_line1
        ? this.capitalizeFirstLetter(obj.address_line1)
        : null;
    this.address_line2 =
      obj && obj.address_line2
        ? this.capitalizeFirstLetter(obj.address_line2)
        : null;
    this.city = obj && obj.city ? this.capitalizeFirstLetter(obj.city) : null;
    this.state = obj && obj.state ? obj.state : null;
    this.postal_code =
      obj && obj.postal_code ? obj.postal_code : obj.zip_code || null;
    this.phone_number = obj && obj.phone_number ? obj.phone_number : null;
    this.email_address = obj && obj.email_address ? obj.email_address : null;
    this.preference_type =
      obj && obj.preference_type ? obj.preference_type : 'address';
  }
}
