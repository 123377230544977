<div class="gated-entry-overlay-alpha-prefix" ngClass.lt-md="mobile">
  <div class="page-container">
    <form [formGroup]="alphaPrefixForm" (ngSubmit)="onAlphaPrefixSubmit()">
      <div class="page-title-icon">
        <img src="/assets/images/member-card.svg" alt="" />
      </div>
      <h2 class="page-title text-center text-light">
        <span>
          {{ 'gated_entry_alpha_prefix_title' | translate }}
        </span>
      </h2>
      <div class="title-separator"></div>
      <div class="form-container">
        <!-- Location Select -->
        <div
          *ngIf="pageTransition.page === 'alpha-prefix-location'"
          [@slideInOutAnimation]="pageTransition.direction"
        >
          <div class="form-section">
            <div
              class="page-description"
              [innerHTML]="'gated_entry_sub_title_location_select' | translate"
            ></div>
            <div class="margin bottom-6x">
              <app-global-location-select
                analytics
                [results]="locationAutosuggest.cities"
                [selectedOption]="
                  locationSelected ? locationAutosuggest.selectedLocation : null
                "
                appearance="outline"
                (termEntered)="locationAutosuggest.onLocationEntered($event)"
                (termSelected)="onLocationSelected($event)"
                (emitBrowserLocationRequested)="
                  locationAutosuggest.requestBrowserLocation()
                "
              >
              </app-global-location-select>
            </div>
            <!-- Location Select Form Buttons -->
            <div
              fxLayout.gt-xs="row"
              fxLayout.lt-sm="column-reverse"
              fxLayoutAlign.gt-xs="end center"
              fxLayoutAlign.lt-sm="start space-between"
              fxLayoutGap.gt-xs="24px"
              fxLayoutGap.lt-sm="16px"
            >
              <div fxFlex.gt-xs="nogrow">
                <button
                  mat-stroked-button
                  class="platform-button"
                  ngClass.lt-sm="button-full"
                  color="accent"
                  type="button"
                  (click)="goBack.emit()"
                >
                  {{ 'app_global_go_back' | translate }}
                </button>
              </div>
              <div fxFlex.gt-xs="240px">
                <button
                  mat-flat-button
                  class="platform-button button-full"
                  color="accent"
                  type="button"
                  [disabled]="(locationAutosuggest.gpsStatus | async)?.loading"
                  (click)="goToOverlayPage.emit({ page: 'alpha-prefix' })"
                >
                  {{ 'app_global_continue' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Alpha Prefix -->
        <div
          *ngIf="pageTransition.page === 'alpha-prefix'"
          [@slideInOutAnimation]="pageTransition.direction"
        >
          <div class="form-section">
            <div
              class="page-description"
              [innerHTML]="'gated_entry_sub_title_alpha_prefix' | translate"
            ></div>
            <div
              class="margin bottom-6x"
              fxLayout.gt-sm="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="start space-between"
              fxLayoutGap.gt-sm="40px"
              fxLayoutGap.lt-md="24px"
            >
              <div class="member-card-container" fxFlex="50">
                <platform-member-card
                  [suppressLogo]="suppressLogo"
                  [magnify]="memberCardMagnify"
                >
                </platform-member-card>
              </div>
              <div fxFlex="50">
                <!-- Alpha Prefix Input -->
                <platform-tokenized-input
                  appUnfocus
                  [count]="alphaPrefixLength"
                  formControlName="alphaPrefix"
                  (keyup)="onAlphaPrefixChange()"
                  (innerFocus)="onAlphaPrefixFocus()"
                >
                </platform-tokenized-input>
                <div class="margin top-3x" *ngIf="networks?.length > 1">
                  <!-- Network Select -->
                  <div>
                    <div
                      [innerHTML]="
                        'network_alpha_prefix_dialog_plan_name_title'
                          | translate
                      "
                    ></div>
                    <div class="margin top-2x">
                      <mat-form-field
                        class="network-select full-width"
                        floatLabel="never"
                        appearance="outline"
                      >
                        <mat-select
                          appUnfocus
                          [placeholder]="
                            'network_alpha_prefix_dialog_plan_name_option'
                              | translate
                          "
                          formControlName="network"
                          (focus)="onNetworkFocus()"
                        >
                          <mat-option
                            *ngFor="
                              let network of networks;
                              trackBy: trackByNetworkId
                            "
                            [value]="network.id"
                          >
                            {{ network.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- Network Error Message -->
                <div
                  class="text-error margin top-2x"
                  *ngIf="networks && networks.length === 0"
                >
                  {{ 'gated_entry_alpha_prefix_no_networks_error' | translate }}
                </div>
              </div>
            </div>
            <!-- Alpha Prefix Form Buttons -->
            <div
              fxLayout.gt-xs="row"
              fxLayout.lt-sm="column-reverse"
              fxLayoutAlign.gt-xs="end center"
              fxLayoutAlign.lt-sm="start space-between"
              fxLayoutGap.gt-xs="24px"
              fxLayoutGap.lt-sm="16px"
            >
              <div fxFlex.gt-xs="nogrow">
                <button
                  mat-stroked-button
                  class="platform-button"
                  type="button"
                  ngClass.lt-sm="button-full"
                  color="accent"
                  (click)="goBack.emit()"
                >
                  {{ 'app_global_go_back' | translate }}
                </button>
              </div>
              <div fxFlex.gt-xs="240px">
                <button
                  mat-flat-button
                  class="platform-button button-full"
                  type="submit"
                  color="accent"
                  [disabled]="alphaPrefixForm.valid === false"
                >
                  {{ 'app_global_continue' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
