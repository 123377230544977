import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalancesComponent } from './balances.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  exports: [BalancesComponent],
  imports: [CommonModule, FlexLayoutModule, DlsIconModule],
  declarations: [BalancesComponent],
})
export class BalancesModule {}
