import { Injectable } from '@angular/core';
import { WindowService } from '../window.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IncompatibleBrowserOverlayComponent } from '../../components/incompatible-browser-overlay';

@Injectable()
export class BrowserDetectService {
  public window: any;
  private overlayOpen: boolean = false;

  constructor(private windowService: WindowService, private dialog: MatDialog) {
    this.window = this.windowService;
  }

  public checkBrowser() {
    const version = this.IEversion();

    if (version !== false && version < 12 && !this.overlayOpen) {
      // trigger overlay for incompatible browser
      this.overlayOpen = true;
      this.dialog.open(IncompatibleBrowserOverlayComponent, {
        width: '600px',
        disableClose: true,
      });
    }
  }

  // obtained from https://codepen.io/gapcode/pen/vEJNZN
  private IEversion() {
    const ua = this.window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}
