import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-uux-help-find-plan',
  templateUrl: './uux-help-find-plan.component.html',
  styleUrls: ['./uux-help-find-plan.component.scss'],
})
export class UuxHelpFindPlanComponent {
  @Input() public alphaPrefixEnabled: boolean = false;

  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
}
