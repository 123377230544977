import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { RouteUtilities } from '@utilities/route.utilities';
import { UuxService } from '@services/uux/uux.service';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { UuxQuestionnaireState } from '@interfaces/uux-questionnaire-state.interface';
import { ArrayUtilities } from '@utilities/array.utilities';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import { first } from 'rxjs/operators';
import { StorageUtilities } from '@utilities/storage.utilities';
import { MarketSegment, SubMarketSegment } from '@enums/uux-marketing-plans.enum';

interface RequestPlans {
  corp_code: string;
  market_segment?: string;
  sub_market_segment?: string; 
  state_code?: string;
}

@Component({
  selector: 'app-uux-questionnaire-options',
  templateUrl: './uux-questionnaire-options.component.html',
})
export class UuxQuestionnaireOptionsComponent implements OnInit, OnDestroy {
  @Input() public marketSegment: string;

  @Output() public setState: EventEmitter<string> = new EventEmitter();
  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
  @Output() public setStatePlans: EventEmitter<UuxNetwork[]> =
    new EventEmitter();
  @Output() public selectUuxNetwork: EventEmitter<UuxNetwork> =
    new EventEmitter();

  public states: UuxQuestionnaireState[];
  public uuxNetworks: UuxNetwork[];
  public trackByStateCode: any = TrackByUtilities.getTrackByFn('state_code');
  public loading: boolean = false;
  public marketSegments = MarketSegment;

  private subscriptions = new SubscriptionManager();
  private routeUtilities = new RouteUtilities();
  private arrayUtilities = new ArrayUtilities();
  private storage = new StorageUtilities();
  private corpCode: string = this.routeUtilities.getParamFromUrl('corp_code');
  private subMarketSegments = Object.values(SubMarketSegment);
  private subMarketSegmentNames: string[] = 
      this.subMarketSegments.map((sub: SubMarketSegment) => sub.replace(' ', ''));

  constructor(private uuxService: UuxService) {}

  ngOnInit() {
    if (this.marketSegment) {
      this.getPlans();
    }
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public selectState(stateCode: string): any {
    this.loading = true;
    this.storage.sessionStorageSet('uuxStateCode', stateCode);

    const requestPlans: RequestPlans = {
      corp_code: this.corpCode,
      ...(this.isSubMarketSegment()
        ? {sub_market_segment: 
            this.subMarketSegments[this.subMarketSegmentNames.indexOf(this.marketSegment)]} 
        : {market_segment: this.marketSegment}
      ),
      state_code: stateCode,
    };

    this.subscriptions.add(
      this.uuxService
        .getPlans(requestPlans)
        .pipe(first())
        .subscribe((plans: UuxNetwork[]) => {
          this.loading = false;
          this.goToPage.emit('plans');
          this.setState.emit(stateCode);
          this.storage.sessionStorageSet('uuxPlans', plans);
          this.setStatePlans.emit(plans);
        })
    );
  }

  private getPlans(): void {
    const requestPlans: RequestPlans = {
      corp_code: this.corpCode,
      ...(this.isSubMarketSegment()
        ? {sub_market_segment: 
            this.subMarketSegments[this.subMarketSegmentNames.indexOf(this.marketSegment)]} 
        : {market_segment: this.marketSegment}
      ),
    };

    this.subscriptions.add(
      this.uuxService
        .getPlans(requestPlans)
        .subscribe((plans: UuxNetwork[]) => {
          if (this.marketSegment === MarketSegment.employer) {
            const savedStates = this.storage.sessionStorageGet('uuxStates');
            this.states =
              savedStates || this.getStatesFromPlans(plans, this.corpCode);
            this.storage.sessionStorageSet('uuxStates', this.states);
          } else {
            this.uuxNetworks = plans;
            this.storage.sessionStorageSet('uuxPlans', this.uuxNetworks);
          }
        })
    );
  }

  private isSubMarketSegment(): boolean {
    return this.subMarketSegmentNames.includes(this.marketSegment);
  }

  private getStatesFromPlans(
    plans: UuxNetwork[],
    corpCode: string
  ): UuxQuestionnaireState[] {
    const statesFromPlans = plans.map((plan: UuxNetwork) => ({
      state_name: plan.state_name,
      state_code: plan.state_code,
    }));
    const uniqueStates = this.arrayUtilities.uniqueByProperty(
      statesFromPlans,
      'state_code'
    );
    uniqueStates.sort((a, b) => a.state_name.localeCompare(b.state_name));
    const corpStateMatchIndex = uniqueStates.findIndex(
      (stateObj) => stateObj.state_code === corpCode
    );
    return this.arrayUtilities.moveArrayItem(
      uniqueStates,
      corpStateMatchIndex,
      0
    );
  }
}
