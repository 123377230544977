import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import {
  MatLegacyList as MatList,
  MatLegacyListItem as MatListItem,
  MatLegacyListModule as MatListModule,
  MatLegacyNavList as MatNavList,
  MatLegacySelectionList as MatSelectionList,
} from '@angular/material/legacy-list';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { DlsAvatarModule } from '@zelis/dls/avatar';
import { DlsHighlightModule } from '@zelis/dls/highlight';
import { DlsIconModule } from '@zelis/dls/icons';
import { DlsLozengeModule } from '@zelis/dls/lozenge';
import { ListItemSubheadComponent } from './list-item-subhead/list-item-subhead.component';
import { ListItemComponent } from './list-item/list-item.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    DlsIconModule,
    DlsAvatarModule,
    DlsLozengeModule,
    MatDividerModule,
    MatRadioModule,
    DlsHighlightModule,
    MatTooltipModule,
    RouterModule.forChild([]),
  ],
  declarations: [ListItemComponent, ListItemSubheadComponent],
  exports: [
    ListItemComponent,
    ListItemSubheadComponent,
    MatList,
    MatListItem,
    MatDivider,
    MatSelectionList,
    MatNavList,
  ],
  providers: [{ provide: Window, useValue: window }],
})
export class DlsListItemModule {}
