import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableSectionComponent } from './expandable-section.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  exports: [ExpandableSectionComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  declarations: [ExpandableSectionComponent],
})
export class ExpandableSectionModule {}
