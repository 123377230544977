<div class="notification-overlay" data-cy="notification-overlay">
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="end" class="close-icon" *ngIf="!data.noClose">
      <button
        (click)="dialogRef.close()"
        class="p-0"
        type="button"
        [attr.aria-label]="'app_global_close_notification' | translate"
      >
        <mat-icon
          alt="close"
          svgIcon="dls-times-plain-light"
          data-cy="notification-overlay.close"
        >
        </mat-icon>
      </button>
    </div>

    <div class="headline pad bottom-2x">
      <h3>
        <strong>{{ data.headline | translate }}</strong>
      </h3>
    </div>

    <div
      class="body pad bottom-2x"
      data-cy="notification-overlay.body"
      [innerHTML]="data.body | translate"
    ></div>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end" class="pad right-2x">
      <div class="submit-row">
        <span *ngIf="!data.buttons">
          <button
            tabindex="0"
            type="button"
            (click)="dialogRef.close()"
            (keydown.enter)="dialogRef.close()"
            mat-flat-button
            class="platform-button view-profile platform-button"
            color="accent"
          >
            {{ 'app_notification_close' | translate }}
          </button>
        </span>

        <!-- Custom behavior -->
        <span *ngIf="data.buttons">
          <button
            *ngFor="let button of data.buttons; let i = index"
            tabindex="0"
            [attr.data-cy]="'notification-overlay.custom-button-' + i"
            type="button"
            (click)="data[button.method]()"
            mat-stroked-button
            class="platform-button margin right-3x"
            mat-stroked-button
            class="platform-button margin left-1x pad top-1x"
            [ngClass]="{ 'external-link-indicator-icon': button.external_link }"
            color="accent"
          >
            {{ button.translation | translate }}
          </button>
        </span>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
