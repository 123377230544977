import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WizardPreviousSelections } from "@interfaces/wizard-previous-selections.interface";
import { PageTransition } from "@interfaces/page-transition.interface";
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";
import { StorageUtilities } from "@utilities/storage.utilities";

@Component({
  selector: 'app-confirm-previous-selections',
  templateUrl: './confirm-previous-selections.component.html',
  styleUrls: ['./confirm-previous-selections.component.scss'],
})
export class ConfirmPreviousSelectionsComponent {
  @Input() previousSelections: WizardPreviousSelections;
  @Output() public goToOverlayPage: EventEmitter<PageTransition> = new EventEmitter();
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  public storage: StorageUtilities = new StorageUtilities();

  constructor(
    private networkSelectWizardService: NetworkSelectionWizardService,
  ) {}

  public onConfirmSelections() {
    this.networkSelectWizardService.setSelections();
    this.closeOverlay.emit();
  }
}

