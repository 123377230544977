import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CloseButtonComponent } from './close-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CloseButtonComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
  providers: [],
  exports: [CloseButtonComponent],
})
export class CloseButtonModule {}
