<div
  [ngClass]="currentPage === 'home' ? 'dark' : 'light'"
  class="cancel-top-margin-8 uux-header"
>
  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="currentPage !== 'home' && currentPage !== 'matching'">
      <button
        (click)="goBack.emit($event)"
        [attr.aria-label]="'app_global_back' | translate"
        class="p-0 h-10"
        type="button"
      >
        <mat-icon svgIcon="dls-angle-plain-left-light" class="cursor-pointer"></mat-icon>
      </button>
    </div>

    <h4 *ngIf="useSegmentTranslation" fxFlex="grow" class="text-bold">
      {{ 'uux_header_' + marketSegment | translate
      }}<span *ngIf="currentPage === 'plans' && selectedState"
        ><strong>: {{ selectedState }}</strong></span
      >
    </h4>
    <h4 *ngIf="!useSegmentTranslation" fxFlex="grow" class="text-bold">
      {{ 'uux_header_' + currentPage | translate }}
    </h4>
    <div fxFlex fxFlexAlign="end">
      <app-close-button
        [contrast]="currentPage === 'home' ? 'dark' : 'light'"
        (closeCalled)="closeMenu.emit($event)"
      >
      </app-close-button>
    </div>
  </div>
</div>
