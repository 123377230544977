import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { LocationService } from '@services/location/location.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Place } from '@classes/place.class';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import { StorageUtilities } from '@utilities/storage.utilities';
import { RouteUtilities } from '@utilities/route.utilities';
import { UuxService } from '@services/uux/uux.service';
import { take } from 'rxjs';
import { MarketSegment } from '@enums/uux-marketing-plans.enum';

@Component({
  selector: 'app-uux-plan-network-list',
  templateUrl: './uux-plan-network-list.component.html',
  styleUrls: ['./uux-plan-network-list.component.scss'],
})
export class UuxPlanNetworkListComponent implements OnInit, OnDestroy {
  @Input() public selectedNetwork: UuxNetwork;
  @Input() public currentPage: string;
  @Input() public marketSegment: string;
  @Input() public planListHeaderTranslationKey: string;
  @Input() public uuxNetworks: UuxNetwork[];

  @Output() public selectPpoPlan: EventEmitter<UuxNetwork> = new EventEmitter();
  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
  @Output() public selectUuxNetwork: EventEmitter<UuxNetwork> =
    new EventEmitter();

  public trackByDisplayName: any = TrackByUtilities.getTrackByFn('name');

  private searchStateCode: string;
  private subscriptions = new SubscriptionManager();
  private storage: StorageUtilities = new StorageUtilities();
  private routeUtilities = new RouteUtilities();

  constructor(
    private locationService: LocationService,
    private uuxService: UuxService
  ) {}

  ngOnInit() {
    this.setSearchStateCode();
    if (!this.uuxNetworks) {
      this.uuxNetworks = this.storage.sessionStorageGet('uuxPlans');
    }
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onSelectUuxNetwork(uuxNetwork: UuxNetwork): void {
    if (this.shouldOpenAlphaPrefix(uuxNetwork)) {
      this.selectPpoPlan.emit(uuxNetwork);
      this.goToPage.emit('alpha');
    } else {
      this.selectUuxNetwork.emit(uuxNetwork);
    }
  }

  public selectAllPlans(): void {
    const corpCode = this.routeUtilities.getParamFromUrl('corp_code');
    const locale = this.routeUtilities.getParamFromUrl('locale');
    this.subscriptions.add(
      this.uuxService
        .getPlansAndCi({ corp_code: corpCode, locale: locale })
        .pipe(take(1))
        .subscribe(([_ci, plans]) => {
          this.storage.sessionStorageSet('uuxPlans', plans);
          this.uuxNetworks = plans;
          let basePlan = plans.find(
            (uuxNetwork: UuxNetwork) => uuxNetwork.defaultPlan
          );
          if (!basePlan) {
            basePlan = this.storage.sessionStorageGet('uuxBasePlan');
          }
          if (basePlan) {
            this.storage.sessionStorageSet('uuxBasePlan', basePlan);
          }
          this.storage.sessionStorageRemove('uuxBreadcrumbs');
          this.storage.sessionStorageRemove('uuxAlphaSelected');
          this.goToPage.emit('home');
          this.selectUuxNetwork.emit(basePlan);
        })
    )
  }

  private shouldOpenAlphaPrefix(plan: UuxNetwork): boolean {
    return (
      plan.ppo_network &&
      this.searchStateCode === 'CA' &&
      this.marketSegment === MarketSegment.employer
    );
  }

  private setSearchStateCode(): void {
    this.subscriptions.add(
      this.locationService.geo.subscribe((geo: Place) => {
        this.searchStateCode = geo && geo.state_code;
      })
    );
  }
}
