import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmPreviousSelectionsComponent } from './confirm-previous-selections.component';
import { DlsButtonModule } from "@zelis/dls/button";
import { MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ConfirmPreviousSelectionsComponent],
  imports: [
    CommonModule,
    DlsButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule
  ],
  exports: [ConfirmPreviousSelectionsComponent]
})
export class ConfirmPreviousSelectionsModule {}
