<div
  class="tokenized-input"
  [ngClass]="{ rounded: rounded }"
  fxLayout="row"
  fxLayoutAlign="space-between start"
  fxLayoutGap="8px"
>
  <div
    *ngFor="let item of tokens; trackBy: trackByFn; let i = index"
    [fxFlex]="100 / count"
  >
    <mat-form-field appearance="outline">
      <input
        matInput
        class="browser-default"
        [ngClass]="{ uppercase: uppercase }"
        type="text"
        maxlength="1"
        [value]="tokens[i]"
        [disabled]="disabled"
        (keyup)="onKeyUp($event, i)"
        (touch)="onTouch($event)"
        (focus)="innerFocus.emit($event)"
        [attr.autocomplete]="autocomplete"
      />
    </mat-form-field>
  </div>
</div>
