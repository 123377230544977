import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MemberCardMagnify,
  SubscriptionManager,
} from '@zelis/platform-ui-components';
import { Network } from '@interfaces/network.model';
import { AlphaPrefixService } from '@services/alpha-prefix.service';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { StorageUtilities } from '@utilities/storage.utilities';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-uux-alpha-prefix',
  templateUrl: './uux-alpha-prefix.component.html',
  styleUrls: ['./uux-alpha-prefix.component.scss'],
})
export class UuxAlphaPrefixComponent implements OnInit, OnDestroy {
  @Input() public ppoPlan: UuxNetwork;

  @Output() public goBack: EventEmitter<boolean> = new EventEmitter();
  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
  @Output() public setPrefix: EventEmitter<string> = new EventEmitter();
  @Output() public selectUuxNetwork: EventEmitter<UuxNetwork> =
    new EventEmitter();

  public alphaPrefixLength: number = 3;
  public alphaPrefixForm: UntypedFormGroup = new UntypedFormGroup({
    alphaPrefix: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(this.alphaPrefixLength),
      Validators.maxLength(this.alphaPrefixLength),
    ]),
  });
  public memberCardMagnify: MemberCardMagnify;
  public showProgressBar: boolean = false;

  private subscriptions: SubscriptionManager = new SubscriptionManager();
  private storage: StorageUtilities = new StorageUtilities();

  constructor(private alphaPrefixService: AlphaPrefixService) {}

  ngOnInit() {
    this.initMemberCardMagnify();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public getAlphaPrefixPlans(): void {
    this.storage.sessionStorageSet('uuxAlphaSelected', true);
    const alphaPrefix = this.alphaPrefixForm.get('alphaPrefix');
    if (alphaPrefix.valid) {
      this.showProgressBar = true;
      return this.getNetworks(alphaPrefix.value);
    }
  }

  public onAlphaPrefixFocus(): void {
    this.magnifyAlphaPrefix();
  }

  public onNetworkFocus(): void {
    this.magnifyPlanName();
  }

  private getNetworks(alphaPrefixCode: string): void {
    this.subscriptions.add(
      this.alphaPrefixService
        .getNetworksByAlphaPrefix(alphaPrefixCode, true)
        .pipe(
          distinctUntilChanged((prev, curr) =>
            isEqual(
              prev.map((n) => n.id),
              curr.map((n) => n.id)
            )
          ),
          map(
            (networks: Network[]) =>
              networks && networks.map((network) => new UuxNetwork(network))
          )
        )
        .subscribe((networks: UuxNetwork[]) => {
          this.showProgressBar = false;
          if (networks && networks.length) {
            this.goToPage.emit('networks');
            this.setPrefix.emit(alphaPrefixCode);
            this.storage.sessionStorageSet('uuxPlans', networks);
            if (networks.length === 1) {
              this.selectUuxNetwork.emit(networks[0]);
            }
          } else {
            // Alpha prefix account match not found
            this.selectUuxNetwork.emit(this.ppoPlan);
          }
        })
    );
  }

  private initMemberCardMagnify(): void {
    setTimeout(() => this.magnifyAlphaPrefix(), 800);
  }

  private magnifyAlphaPrefix(): void {
    this.memberCardMagnify = 'ALPHA_PREFIX';
  }

  private magnifyPlanName(): void {
    this.memberCardMagnify = 'PLAN_NAME';
  }
}
