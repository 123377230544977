export class ArrayUtilities {
  public moveArrayItem(
    array: any[],
    fromIndex: number,
    toIndex: number
  ): any[] {
    const arr = [...array];
    arr.splice(toIndex, 0, ...arr.splice(fromIndex, 1));
    return arr;
  }

  public uniqueByProperty(array: any[], property: any): any[] {
    const uniqueArray = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item[property])) {
        map.set(item[property], true);
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }
}
