import { MatIconModule } from '@angular/material/icon';
import { CloseButtonModule } from '../../close-button/close-button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxHeaderComponent } from './uux-header.component';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [UuxHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    CloseButtonModule,
    DlsIconModule
  ],
  exports: [UuxHeaderComponent],
})
export class UuxHeaderModule {}
