import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxPlanNetworkListComponent } from './uux-plan-network-list.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { GtmModule } from '../../../directives/gtm/gtm.module';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';

@NgModule({
  declarations: [UuxPlanNetworkListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    GtmModule,
    DlsProgressSpinnerModule,
  ],
  exports: [UuxPlanNetworkListComponent],
})
export class UuxPlanNetworkListModule {}
