<div class="uux-alpha-prefix-container" ngClass.lt-md="mobile">
  <form [formGroup]="alphaPrefixForm">
    <div class="form-container">
      <!-- Alpha Prefix -->
      <div class="form-section">
        <div
          class="page-description"
          [innerHTML]="'uux_subheader_alpha_prefix' | translate"
        ></div>
        <div
          class="margin bottom-2x"
          fxLayout="column"
          fxLayoutAlign="start space-between"
          fxLayoutGap.gt-sm="16px"
          fxLayoutGap.lt-md="24px"
        >
          <div class="member-card-container">
            <platform-member-card
              [suppressLogo]="true"
              [magnify]="memberCardMagnify"
            >
            </platform-member-card>
          </div>
          <!-- Alpha Prefix Input -->
          <div fxLayout="row" fxLayoutAlign="center" class="set-width">
            <div class="set-width-input">
              <platform-tokenized-input
                appUnfocus
                [count]="alphaPrefixLength"
                formControlName="alphaPrefix"
                (innerFocus)="onAlphaPrefixFocus()"
              >
              </platform-tokenized-input>
              <mat-progress-bar
                class="margin top-2x"
                *ngIf="showProgressBar"
                color="primary"
                mode="indeterminate"
              >
              </mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Alpha Prefix Form Buttons -->
  <div
    ngClass.lt-md="pad x-2x"
    fxLayout.gt-xs="row"
    fxLayout.lt-sm="column-reverse"
    fxLayoutAlign="center"
    fxLayoutGap.gt-xs="24px"
    fxLayoutGap.lt-sm="16px"
  >
    <div fxFlex.gt-xs="nogrow">
      <zelis-button
        size="large"
        fullWidth="true"
        type="button"
        variant="stroked"
        color="accent"
        (keydown.enter)="goBack.emit($event)"
        (click)="goBack.emit($event)"
        appGtm
        [gtmOnClick]="true"
        [gtmData]="{
          event: 'alpha-pre-fix',
          label: 'app_global_go_back' | translate,
          alphaPreFixMemberId: alphaPrefixForm.get('alphaPrefix').value
        }"
      >
        {{ 'app_global_go_back' | translate }}
      </zelis-button>
    </div>
    <div fxFlex.gt-xs="240px">
      <zelis-button
        color="accent"
        size="large"
        fullWidth="true"
        (click)="getAlphaPrefixPlans()"
        (keydown.enter)="getAlphaPrefixPlans()"
        [disabled]="alphaPrefixForm.valid === false"
        appGtm
        [gtmOnClick]="true"
        [gtmData]="{
          event: 'alpha-pre-fix',
          label: 'app_global_continue' | translate,
          alphaPreFixMemberId: alphaPrefixForm.get('alphaPrefix').value
        }"
      >
        {{ 'app_global_continue' | translate }}
      </zelis-button>
    </div>
  </div>
</div>
