import { NgModule } from '@angular/core';
import { MaterialModule } from '../material';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MsaRouting } from './msa.routes';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsaFormComponent } from './msa.form.component';
import { MsaSettingsService } from './msa.settings.service';
import { GlobalHeaderSettings } from '../global-header/global-header-settings.class';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MsaRouting,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
  ],
  providers: [MsaSettingsService, GlobalHeaderSettings],
  declarations: [MsaFormComponent],
})
export class MsaModule {}
