import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenizedInputComponent } from './tokenized-input.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  exports: [TokenizedInputComponent],
  imports: [CommonModule, FlexLayoutModule, MatFormFieldModule, MatInputModule],
  declarations: [TokenizedInputComponent],
})
export class TokenizedInputModule {}
