import { Component, EventEmitter, Output } from '@angular/core';
import { MarketSegment, SubMarketSegment } from '@enums/uux-marketing-plans.enum';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';

@Component({
  selector: 'app-uux-questionnaire',
  templateUrl: './uux-questionnaire.component.html',
  styleUrls: ['./uux-questionnaire.component.scss'],
})
export class UuxQuestionnaireComponent {
  @Output() public goToPage: EventEmitter<string> = new EventEmitter();
  @Output() public setMarketSegment: EventEmitter<string> = new EventEmitter();

  public questionnaireOptions: (MarketSegment | SubMarketSegment)[] = [
    MarketSegment.employer,
    MarketSegment.retail,
    SubMarketSegment.retailMedicare,
    SubMarketSegment.groupMedicare,
    MarketSegment.medicaid
  ];
  public trackByFnIndex: any = TrackByUtilities.trackByFnIndex;

  constructor() {}

  public selectMarketSegment(option: string): any {
    this.goToPage.emit('options');
    this.setMarketSegment.emit(option);
  }
}
