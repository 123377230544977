import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from '@zelis/platform-ui-components';
import { GlobalLocationSelectComponent } from './global-location-select.component';
import { FlexModule } from '@angular/flex-layout';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [GlobalLocationSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    AnalyticsModule,
    FlexModule,
    DlsProgressSpinnerModule,
    DlsIconModule
  ],
  providers: [],
  exports: [GlobalLocationSelectComponent],
})
export class GlobalLocationSelectModule {}
