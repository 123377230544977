import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-uux-header',
  templateUrl: './uux-header.component.html',
  styleUrls: ['./uux-header.component.scss'],
})
export class UuxHeaderComponent implements OnChanges {
  @Input() public currentPage: string;
  @Input() public marketSegment: string;
  @Input() public selectedState: string;

  @Output() public goBack: EventEmitter<boolean> = new EventEmitter();
  @Output() public closeMenu: EventEmitter<boolean> = new EventEmitter();

  public useSegmentTranslation: boolean = false;

  ngOnChanges() {
    this.setUseSegmentTranslation();
  }

  public setUseSegmentTranslation(): void {
    this.useSegmentTranslation =
      this.currentPage === 'options' || this.currentPage === 'plans';
  }
}
