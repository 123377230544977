<div
  class="py-3 px-4 flex justify-between items-center"
  [class.border-t]="divider === true"
  [class.bg-white]="transparentBackground === false"
  [class.bg-transparent]="transparentBackground === true"
>
  <h4 *ngIf="size === 'large'" class="heading-4 font-bold text-black">
    {{ primaryText }}
  </h4>
  <h4
    *ngIf="size === 'small'"
    class="heading-6 font-medium text-palette-m3-system-gray-35"
  >
    {{ primaryText }}
  </h4>
  <h5
    *ngIf="secondaryText"
    class="heading-6 font-medium text-palette-m3-system-gray-35"
  >
    {{ secondaryText }}
  </h5>
</div>
