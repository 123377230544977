import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchCheckboxComponent } from '@components/+search/search-checkbox/search-checkbox.component';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, FlexLayoutModule],
  declarations: [SearchCheckboxComponent],
  exports: [SearchCheckboxComponent],
})
export class SearchCheckboxModule {}
