import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalNetworkDropdownComponent } from './global-network-dropdown.component';
import { FixedTooltipModule } from '../../fixed-tooltip/fixed-tooltip.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NetworkAlphaPrefixDialogModule } from '../../network-alpha-prefix-dialog/network-alpha-prefix-dialog.module';

@NgModule({
  declarations: [GlobalNetworkDropdownComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    TranslateModule,
    FixedTooltipModule,
    MatDialogModule,
    NetworkAlphaPrefixDialogModule,
    MatBottomSheetModule,
  ],
  providers: [],
  exports: [GlobalNetworkDropdownComponent],
})
export class GlobalNetworkDropdownModule {}
