<div
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!!member?.id"
  class="msa-banner-container"
>
  <div fxFlex="5"></div>

  <div fxFlex="80" class="msa-banner-main">
    <div>
      {{ 'msa_banner_memberName_label' | translate }}
      {{ member.full_name | translate }}
    </div>
    <div>
      {{ 'msa_banner_memberId_label' | translate }} {{ member.subscriber_id }}
    </div>
    <div class="msa-start-over" *ngIf="showStartOver()">
      <button
        (click)="newMember()"
        mat-raised-button
        type="button"
      >
        {{ 'msa_banner_newMember_button' | translate }}
      </button>
    </div>
  </div>

  <div fxLayoutAlign="end center" fxFlex="10">
    <button 
      (click)="logout()"
      mat-raised-button
      class="link-blue"
      type="button"
    >
      {{
        'msa_banner_logoutSession_button' | translate
      }}
    </button>
  </div>

  <div fxFlex="5"></div>
</div>
