import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxContainerComponent } from './uux-container.component';

@NgModule({
  declarations: [UuxContainerComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [UuxContainerComponent],
})
export class UuxContainerModule {}
