import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NetworkSelectionWizardComponent } from '@components/network-selection-wizard/network-selection-wizard.component';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { AuthStatus } from '@interfaces/auth-status.model';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { combineLatest, of, first, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Place } from "@classes/place.class";
import { UuxNetwork } from "@interfaces/uux-network.model";
import { CriticalParamsService } from "@services/critical-params/critical-params.service";

@Injectable({
  providedIn: 'root'
})
export class NetworkSelectionWizardService {
  public isOpened: boolean = false;
  public isReturnUser: boolean = this.checkIfReturnUser();
  public isDefaultLocationPage: boolean = true;
  
  constructor(
    private storage: StorageUtilities,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private criticalParamsService: CriticalParamsService,
  ) {}
  
  public getWizard(auth: AuthStatus): Observable<boolean> {
    return combineLatest([
      this.settingsService
        .getSetting('gated_entry', GatedEntryOverlayConfig)
        .pipe(first()),
    ]).pipe(
      switchMap(([gatedEntry]) => {
        const shouldOpenWizard = this.shouldOpenWizard(auth, gatedEntry.enabled);
        if (shouldOpenWizard && !this.isOpened) {
          this.openNetworkSelectWizard(auth.auth_status);
          return of(true);
        }
        return of(false);
      })
    );
  }

  public saveSelectedLocation(location: Place): void {
    this.storage.localStorageSet('savedLocation', location);
  }

  public getSavedLocation(): Place {
    return this.storage.localStorageGet('savedLocation');
  }

  public saveSelectedPlan(plan: UuxNetwork): void {
    this.storage.localStorageSet('savedNetwork', plan);
  }

  public getSavedPlan(): UuxNetwork {
    return this.storage.localStorageGet('savedNetwork');
  }
  
  public setSelections() {
    const savedPlan = this.getSavedPlan();
    const savedLocation = this.getSavedLocation();
    
    this.storage.sessionStorageSet('uuxSelectedNetwork', savedPlan);
    this.criticalParamsService.setCriticalParams({
      ci: savedPlan.ci,
      geo_location: savedLocation.geo,
    });
  }
  
  public setDefaultLocationPage(condition: boolean) {
    this.isDefaultLocationPage = condition;
  }
  
  private checkIfReturnUser() {
    const savedLocation= this.getSavedLocation();
    const savedPlan = this.getSavedPlan();
    const wizardCompleted = this.storage.localStorageGet('wizardComplete');
    return !!savedLocation && !!savedPlan && !!wizardCompleted;
  }
  
  private openNetworkSelectWizard(loggedIn: boolean): void {
    const sheetRef =
      this.bottomSheet.open(NetworkSelectionWizardComponent, {
        panelClass: 'full-screen-sheet',
        disableClose: true,
        hasBackdrop: false,
        data: {
          loggedIn: loggedIn,
        },
        ariaModal: false,
        ariaLabel: this.translateService.instant('gated_entry_home_title')
      });
    sheetRef.afterOpened().subscribe(() => this.isOpened = true);
    sheetRef.afterDismissed().subscribe(() => this.endWizardSession());
  }
  
  private shouldOpenWizard(authStatus: AuthStatus, gatedEntryEnabled: boolean) {
    const isAuthenticated = authStatus.auth_status === true;
    
    switch (true) {
      case (gatedEntryEnabled):
        return false;
      case (isAuthenticated):
        return false;
      default:
        return true;
    }
  }

  private endWizardSession(): void {
    this.storage.sessionStorageRemove('wizardPageHistory');
    this.storage.sessionStorageRemove('wizardCurrentPage');
  }
}
