import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appUnfocus]',
})
export class UnfocusDirective {
  private TAB: number = 9;
  private SHIFT: number = 16;
  private cssClasses: string[] = [
    '.autosuggest-full-width.mat-form-field',
    '.mat-input-wrapper.mat-form-field-wrapper',
    '.mat-form-field.mat-focused',
    '.ng-star-inserted',
    '.geo-icon',
    '.more-filters-button',
    '.credentials-link',
    '.link',
    '.account-button',
    '.logo-wrapper',
    '.rating',
    '.browser-default',
    '.mat-expansion-panel-header',
  ];

  @HostListener('click', ['$event.target'])
  onClick(target) {
    target.classList.add('no-halo');
    this.cssClasses.forEach((cssClass) => {
      if (target.closest(cssClass)) {
        target.closest(cssClass).classList.add('no-halo');
      }
    });
  }
  @HostListener('keyup', ['$event'])
  onKeyup(event) {
    if (event.keyCode === this.TAB || event.keyCode === this.SHIFT) {
      event.target.classList.remove('no-halo');
      this.cssClasses.forEach((cssClass) => {
        if (event.target.closest(cssClass)) {
          event.target.closest(cssClass).classList.remove('no-halo');
        }
      });
    }
  }
}
