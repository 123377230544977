<div
  *ngIf="alphaPrefixEnabled"
  mat-menu-item
  (click)="goToPage.emit('alpha')"
  class="w-100"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxFlex="grow">
    <mat-icon svgIcon="mdx-takes-your-card"></mat-icon>
    {{ 'uux_help_start_alpha' | translate }}
  </div>

  <div fxFlex fxFlexAlign="end">
    <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
  </div>
</div>

<mat-divider></mat-divider>

<div
  class="w-100 questionnaire-button"
  data-cy="uux.questionnaire-button"
  mat-menu-item
  (click)="goToPage.emit('questionnaire')"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  appGtm
  [gtmOnClick]="true"
  [gtmData]="{
    event: 'answering-a-few-quick-questions',
    action: 'uux_help_start_questionnaire' | translate
  }"
>
  <div class="text-bold" fxFlex="grow">
    <mat-icon svgIcon="mdx-spyglass"></mat-icon>
    {{ 'uux_help_start_questionnaire' | translate }}
  </div>

  <div class="arrow-icon-container" fxFlex fxFlexAlign="end">
    <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
  </div>
</div>

<mat-divider></mat-divider>
