<button
  mat-stroked-button
  class="guided-search-button platform-button button-mobile-text"
  data-cy="guided-search-trigger.button"
  *ngIf="isEnabled | async"
  [ngClass]="ccssService.getClass('guided_search')"
  (click)="onTriggerClick()"
  [disabled]="(isReady | async) !== true"
>
  <span *ngIf="breakpoints.isMobile">{{
    'guided_search_browse' | translate
  }}</span>
  <span *ngIf="breakpoints.isDesktop">{{
    'home_browse_by_category' | translate
  }}</span>
</button>
