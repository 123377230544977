import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { PrintData } from '@interfaces/print.model';
import { Store, select } from '@ngrx/store';
import { selectPrintData } from '@store/print/print.selectors';
import { removePrintItemStateAction, setPrintItemStateAction } from '@store/print/print.actions';
import { SnackBarOptions, SnackbarService } from '@zelis/dls/snackbar';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionManager } from '@zelis/platform-ui-components';

@Component({
  selector: 'app-save-for-print',
  templateUrl: './save-for-print.component.html',
  styleUrls: ['./save-for-print.component.scss'],
})
export class SaveForPrintComponent implements OnInit, OnChanges, OnDestroy {
  @Input() printData: PrintData;
  @Input() showCheckbox: boolean = false;
  @Input() showPrintPageLink: boolean = false;
  public saved: boolean = false;
  public printCount: number;

  private subscriptions: SubscriptionManager = new SubscriptionManager();

  constructor(
    private store: Store,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.printItemCount();
    this.subscribeToRouteParams();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.printData) {
      this.checkIfSavedToPrint();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }

  public saveToPrintList(): void {
    this.store.dispatch(
      setPrintItemStateAction({ printState: this.printData })
    );
    this.saveToSessionStorage();
    this.openSnackBar();
  }

  public deleteFromPrintList(): void {
    this.store.dispatch(
      removePrintItemStateAction({ printState: this.printData })
    );
    this.saveToSessionStorage();
    this.openSnackBar();
  }

  private saveToSessionStorage(): void {
    this.subscriptions.add(
      this.store.pipe(select(selectPrintData))
      .subscribe((data) => {
        sessionStorage.setItem('printData', JSON.stringify(data));
      })
    );
  }

  private openSnackBar(): void {
    this.snackbarService.open({
      data: {
        message: this.saved
          ? this.translate.instant('tinc_print_rates_save_for_print_snackbar')
          : this.translate.instant(
              'tinc_print_rates_remove_for_print_snackbar'
            ),
        icon: 'dls-check-circle-light',
        cta: {
          label: 'View',
          callback: () => {
            this.goToPrintPage();
          },
        },
        closeBtn: true,
      } as SnackBarOptions,
    } as MatSnackBarConfig);
  }

  private printItemCount(): void {
    this.subscriptions.add(
      this.store
      .pipe(select(selectPrintData))
      .subscribe((data) => {
        if (data) {
          return (this.printCount = data['ids'].length);
        }
      })
    );
  }

  private checkIfSavedToPrint(): void {
    this.subscriptions.add(
      this.store
      .pipe(select(selectPrintData))
      .subscribe((data) => {
        if (data) {
          this.saved = data['ids'].includes(this.printData.id);
        }
      })
    );
  }

  private goToPrintPage(): void {
    this.router.navigate(['saved-providers'], {
      queryParamsHandling: 'merge',
    });
  }

  private subscribeToRouteParams(): void {
    this.subscriptions.add(
      combineLatest([
        of(this.route.params),
        of(this.route.queryParams)
      ]).subscribe(() => {
        sessionStorage.setItem(
          'previousPageURL',
          JSON.stringify(window.location.href)
        );
      })
    );
  }
}
