import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  OnChanges,
} from '@angular/core';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { StorageUtilities } from '@utilities/storage.utilities';
import { SearchFilter } from '@interfaces/search-filter.model';

@Component({
  selector: 'app-search-checkbox',
  styleUrls: ['./search-checkbox.component.scss'],
  templateUrl: './search-checkbox.component.html',
})
export class SearchCheckboxComponent implements OnInit, OnChanges {
  @Input() public filter: SearchFilter;

  @Output() filterSelected = new EventEmitter();

  @ViewChild(MatSlideToggle) slideToggle: MatSlideToggle;

  private storage: StorageUtilities = new StorageUtilities();

  constructor() {}

  ngOnInit() {
    this.checkFilterSelected();
  }

  ngOnChanges() {
    this.checkFilterSelected();
  }

  public facetOptionSelected(filter: SearchFilter): void {
    if (this.filter) {
      this.filter.selected = !filter.selected ? 'Y' : null;
      this.filterSelected.emit(this.createEmit(filter));
    }
  }

  private checkFilterSelected(): void {
    const sessionFilters = this.storage.sessionStorageGet('searchFilters');

    if (this.filter && sessionFilters && sessionFilters[this.filter.facet]) {
      this.filter.selected = sessionFilters[this.filter.facet];
    }
  }

  private createEmit(filter: SearchFilter): any {
    switch (true) {
      case (
        filter.facet === 'contract_accepting_new_patients' &&
        filter.selected &&
        filter.selected === 'Y'
      ):
        return { [filter.facet]: 'F,Y,EO,OE,OX,O,XO' };
      case (
        filter.facet === 'verified_data' &&
        filter.selected &&
        filter.selected === 'Y'
      ):
        return { [filter.facet]: true };
      case (
        filter.name === 'in_person_care' &&
        filter.facet === 'virtual_only' &&
        !!filter.selected
      ):
        return { [filter.facet]: 'N' };
      default:
        return { [filter.facet]: filter.selected };
    }
  }
}
