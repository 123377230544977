import { Injectable } from '@angular/core';
import { AlphaListItem } from './alpha-list.interface.item';
import { AlphaListLegend } from './alpha-list.interface.legend';
import { StringParseUtilities } from '../../utilities/string-parse.utilities';
import { each, sortBy } from 'lodash';

const stringParseUtilities = new StringParseUtilities();
const getBracketText = stringParseUtilities.getBracketText;
const trimBeginningWhitespace = stringParseUtilities.trimBeginningWhitespace;
const getDashesParenthesesText = stringParseUtilities.getDashesParenthesesText;
const trimSpaces = stringParseUtilities.trimSpaces;

@Injectable({
  providedIn: 'root',
})
export class AlphaListService {
  // TODO: Refactor to use observables or NgRX
  public search_specialties: any[] = [];
  public provider_types: any[] = [];
  public procedures: any[] = [];
  public incentivized_procedures: any[] = [];
  public activeList = '';
  public activeListFiltered: AlphaListItem[];
  public activeLetters: AlphaListLegend;
  public activeLegendLettersListIndices: AlphaListLegend;

  constructor() {
    this.resetActiveLetters();
  }

  public alphabetize(list: any[], listType: string, lang?: string): void {
    this.resetActiveLetters();
    const defendedList = this.mapListItems(list, lang, listType);
    this[listType] = sortBy(defendedList, (obj) => obj.name.toLowerCase());
    this.set(listType);
  }

  public set(type: string): void {
    if (this[type]) {
      this.getActiveLetters(type);
      this.activeListFiltered = this[type];
    }
  }

  public filter(query: string): void {
    if (query && query.length > 1) {
      this.resetActiveLetters();
      this.activeListFiltered = Object.assign([], this[this.activeList]).filter(
        (item: AlphaListItem) => {
          return this.queryMatchesNameOrDescription(item, query);
        }
      );
    } else {
      this.set(this.activeList);
    }
  }

  public getFocusIndex(letter: string): number {
    const i = this.activeLetters[this.activeList].indexOf(letter);
    return this.activeLegendLettersListIndices[this.activeList][i];
  }

  public showLetter(i: number): boolean {
    return this.activeLegendLettersListIndices[this.activeList].indexOf(i) > -1;
  }

  private mapListItems(
    list: any[],
    lang?: string,
    type?: string
  ): AlphaListItem[] {
    return list
      .map((item) => new AlphaListItem(item, lang, type))
      .filter((item) => item.name && !item.suppress_display)
      .map((item) => this.nameSortBracketDefense(item, 'name'));
  }

  private resetActiveLetters(): void {
    this.activeLetters = new AlphaListLegend();
    this.activeLegendLettersListIndices = new AlphaListLegend();
  }

  private getActiveLetters(type: string): void {
    each(this[type], (item: AlphaListItem, index: number) => {
      if (this.activeLetters[type].indexOf(item.prefix) === -1) {
        this.activeLetters[type].push(item.prefix);
        this.activeLegendLettersListIndices[type].push(index);
      }
    });
  }

  /**
   * This function is used while looping through the procedures array
   * to check if the procedure name starts with brackets including (), {}, []. If so,
   * then the bracketed piece of the text will be moved to the end
   * of the name so alpha sort will be able to sort correctly
   * and not use the bracket as the first letter of the procedure name.
   */
  private nameSortBracketDefense(
    item: AlphaListItem,
    field: string
  ): AlphaListItem {
    if (item[field]) {
      const originalString = item[field].search(getBracketText);
      if (originalString !== -1) {
        const newString = item[field].replace(getBracketText, '');
        const trimmed = newString.replace(trimBeginningWhitespace, '');
        const trimmedBrackets = getBracketText.exec(item[field])[0];
        item[field] = trimmed + ' ' + trimmedBrackets;
      }
    }
    return item;
  }

  /**
   * This function is used while querying the procedures and specialties
   * array to eliminate spaces, dashes and parentheses from the names
   * so the user will be able to filter as expected without considering
   * dashes and parentheses in our terms.
   */
  private nameFilterTrim(item: string): string {
    let out = '';

    if (item) {
      out = item.replace(trimSpaces, '');
      const originalString = out.search(getDashesParenthesesText);
      if (originalString !== -1) {
        const newString = out.replace(getDashesParenthesesText, '');
        out = newString;
      }
      return out.toLowerCase();
    }
    return '';
  }

  private queryMatchesNameOrDescription(
    item: AlphaListItem,
    query: string
  ): boolean {
    const trimmedQuery = this.nameFilterTrim(query);
    const trimmedName = this.nameFilterTrim(item['name']);
    const trimmedDescription = this.nameFilterTrim(item['description']);
    return (
      trimmedName.indexOf(trimmedQuery) > -1 ||
      trimmedDescription.indexOf(trimmedQuery) > -1
    );
  }
}
