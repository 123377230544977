<div class="text-palette-m3-system-gray-10 px-6 md:p-0 text-center flex sm:flex-col sm:items-center">
  <div class="max-w-[582px]">
    <div class="header-content mb-10">
      <h3 class="heading-3 font-bold">
        {{ 'network_selection_wizard_confirm_selections_header' | translate }}
      </h3>
      <div class="text-base mt-2">
        {{ 'network_selection_wizard_confirm_selections_description' | translate }}
      </div>
    </div>

    <div class="body flex flex-col confirm-selection-field">
      <mat-form-field appearance="outline">
        <mat-label class="text-gray-900" [innerHTML]="previousSelections.location"></mat-label>
        <input [disabled]="true" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="text-gray-900" [innerHTML]="previousSelections.plan"></mat-label>
        <input [disabled]="true" matInput>
      </mat-form-field>
    </div>

    <div class="button-container flex justify-end">
      <zelis-button
        class="mr-4"
        size="medium"
        color="system-blue"
        variant="stroked"
        (click)="goToOverlayPage.emit({ page: 'modify-previous-selections' })"
      >
        {{ 'network_selection_wizard_confirm_selections_modify' | translate }}
      </zelis-button>
      <zelis-button
        size="medium"
        color="system-blue"
        variant="flat"
        (click)="onConfirmSelections()"
      >
        {{ 'network_selection_wizard_confirm_selections' | translate }}
      </zelis-button>
    </div>
  </div>
</div>
