<div class="proactive-chat-container" [ngClass.lt-md]="'proactive-chat-mobile'">
  <div class="secondary-content margin bottom-2x">
    {{ 'pat_chat_proactive_message' | translate: { name: memberName } }}
  </div>

  <div fxLayout="column" fxLayoutGap="16px">
    <div>
      <app-chat-button
        *ngIf="isAvailable | async"
        chatId="sticky_fab"
        [buttonCopy]="'app_global_yes' | translate"
      >
      </app-chat-button>
    </div>

    <div>
      <button
        mat-stroked-button
        class="platform-button button-full"
        color="accent"
        (click)="proactiveNoClick()"
        data-cy="proactive-chat-container.button-no"
      >
        {{ 'app_global_no' | translate }}
      </button>
    </div>
  </div>
</div>
