import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationOverlayComponent } from './notification-overlay.component';

@NgModule({
  declarations: [NotificationOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
  ],
  providers: [],
  exports: [NotificationOverlayComponent],
})
export class NotificationOverlayModule {}
