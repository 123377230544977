import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CloseButtonModule } from '../../close-button/close-button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxQuestionnaireOptionsComponent } from './uux-questionnaire-options.component';
import { UuxPlanNetworkListModule } from '../uux-plan-network-list/uux-plan-network-list.module';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';

@NgModule({
  declarations: [UuxQuestionnaireOptionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    CloseButtonModule,
    UuxPlanNetworkListModule,
    DlsProgressSpinnerModule,
  ],
  exports: [UuxQuestionnaireOptionsComponent],
})
export class UuxQuestionnaireOptionsModule {}
