<div class="uux-home-container" data-cy="uux.home-container">
  <div *ngIf="showHelpScreen">
    <div
      mat-menu-item
      (click)="goToPage.emit('help')"
      class="w-100"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxFlex="grow">
        <mat-icon svgIcon="mdx-spyglass"></mat-icon>
        {{ 'uux_home_start_help' | translate }}
      </div>

      <div fxFlex fxFlexAlign="end">
        <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!showHelpScreen">
    <app-uux-help-find-plan (goToPage)="goToPage.emit('questionnaire')">
    </app-uux-help-find-plan>
  </div>

  <!-- Initial dropdown plan list -->
  <app-uux-plan-network-list
    [uuxNetworks]="uuxNetworks"
    [selectedNetwork]="selectedNetwork"
    [planListHeaderTranslationKey]="'uux_subheader_home_plan_list'"
    (goToPage)="goToPage.emit($event)"
    (selectUuxNetwork)="selectUuxNetwork.emit($event)"
  >
  </app-uux-plan-network-list>
</div>
