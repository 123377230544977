import {
  Directive,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
  Inject,
  OnDestroy,
  Input,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  FocusMonitor,
  FocusOrigin,
  ConfigurableFocusTrapFactory,
  ConfigurableFocusTrap,
  InteractivityChecker,
} from '@angular/cdk/a11y';
import { Observable, Subscription } from 'rxjs';
import { coerceBoolean } from 'coerce-property';
import { TrapFocusFocusableElements } from './trap-focus.interfaces';

@Directive({
  selector: '[zelisTrapFocus]',
})
export class TrapFocusDirective implements AfterViewInit, OnDestroy {
  @Input() @coerceBoolean autoTrap?: boolean;

  private focusableElementSelectors = [
    'a[href]',
    'button',
    'textarea',
    'input[type="text"]',
    'input[type="radio"]',
    'input[type="checkbox"]',
    'select',
    '[tabindex]',
  ];
  private previousActiveElement: any;
  private focusMonitorSubscription?: Subscription;
  private cdkFocusTrap?: ConfigurableFocusTrap;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdkFocusTrapFactory: ConfigurableFocusTrapFactory,
    private focusMonitor: FocusMonitor,
    private interactivityChecker: InteractivityChecker,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngAfterViewInit() {
    this.initCdkFocusTrap();
    this.initAutoTrap();
    this.initFocusMonitor();
  }

  ngOnDestroy() {
    this.cdkFocusTrap?.destroy();
    this.focusMonitorSubscription?.unsubscribe();
    this.focusMonitor.stopMonitoring(this.getBodyElement());
  }

  private initCdkFocusTrap(): void {
    this.cdkFocusTrap = this.cdkFocusTrapFactory.create(this.el.nativeElement, {
      // The CDK focus trap will not automatically move focus into the trapped region when it initializes.
      defer: true,
    });
  }

  private initAutoTrap(): void {
    if (this.autoTrap) {
      this.returnFocusToTrap();
    }
  }

  private initFocusMonitor(): void {
    this.focusMonitorSubscription = this.getFocusMonitor().subscribe(
      (origin: FocusOrigin) => {
        this.ngZone.run(() => {
          const activeElement = this.getActiveElement();
          const isChildOfTrap = this.elementIsChildOf(
            activeElement,
            this.el.nativeElement
          );
          const isMenuItem = this.elementIsMenuItem(activeElement);
          if (isChildOfTrap) {
            this.previousActiveElement = activeElement;
          } else if (!isMenuItem) {
            if (origin === 'program') {
              this.focusPreviousFocusableElement();
            } else if (origin === 'keyboard') {
              this.focusFirstFocusableElement();
            } else {
              this.returnFocusToTrap();
            }
          }

          this.cdr.markForCheck();
        });
      }
    );
  }

  private getFocusMonitor(): Observable<FocusOrigin> {
    return this.focusMonitor.monitor(this.getBodyElement(), true);
  }

  private elementIsChildOf(child: Element | null, parent: Element): boolean {
    let currentNode = child;
    while (currentNode !== null) {
      if (currentNode === parent) {
        return true;
      }
      currentNode = currentNode.parentElement;
    }
    return false;
  }

  private elementIsMenuItem(element: any): boolean {
    return element?.getAttribute('role') === 'menuitem';
  }

  private getFocusableElements(): TrapFocusFocusableElements {
    const focusableEls = this.el.nativeElement.querySelectorAll(
      this.focusableElementSelectors.join(', ')
    );
    const focusableElsFiltered = Array.from(focusableEls).filter((el: any) =>
      this.elementIsInteractive(el)
    );
    const firstFocusableEl: any = focusableElsFiltered[0];
    const lastFocusableEl: any =
      focusableElsFiltered[focusableElsFiltered.length - 1];
    const activeElement = this.getActiveElement();
    return {
      all: focusableElsFiltered,
      first: firstFocusableEl,
      last: lastFocusableEl,
      active: activeElement,
    };
  }

  private focusFirstFocusableElement(): void {
    const focusableElements = this.getFocusableElements();
    this.focusMonitor.focusVia(focusableElements.first, 'program');
  }

  private focusPreviousFocusableElement(): void {
    if (this.elementIsInteractive(this.previousActiveElement)) {
      this.focusMonitor.focusVia(this.previousActiveElement, 'program');
      return;
    }
    this.focusFirstFocusableElement();
  }

  private getBodyElement(): any {
    return this.document.body;
  }

  private getActiveElement(): any {
    return (
      this.document.activeElement?.shadowRoot?.activeElement ||
      this.document.activeElement
    );
  }

  private returnFocusToTrap(): void {
    this.el.nativeElement.focus();
  }

  private elementIsInteractive(element: any): boolean {
    if (!element) {
      return false;
    }
    return (
      this.interactivityChecker.isVisible(element) &&
      this.interactivityChecker.isFocusable(element) &&
      this.interactivityChecker.isTabbable(element)
    );
  }
}
