import { ParsedUrl } from '@interfaces/parsed-url.model';
import { StringifyParsedUrlOptions } from '@interfaces/stringify-parsed-url-options.model';
import { HttpMockConfigIgnoredQueryParam } from '@interfaces/http-mock-config-ignored-query-param.interface';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { SearchRouteType } from '@interfaces/search-route-type.interface';
import { find, includes } from 'lodash';
import { Injectable } from '@angular/core';
import { SearchFilter } from '@interfaces/search-filter.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RouteUtilities {
  public criticalParams: string[] = [
    'geo_location',
    'network_id',
    'locale',
    'ci',
  ];

  public parseUrl(url?: string): ParsedUrl {
    url = url || window.location.href;
    const parser = document.createElement('a');
    parser.href = url;
    return new ParsedUrl(parser);
  }

  public getRoutePath(url?: string): string {
    return this.parseUrl(url).pathname;
  }

  public getParamsFromUrl(url?: string): any {
    const urlString = url || window.location.href;
    return this.parseUrl(urlString).searchObject;
  }

  /**
   * Get a single param by name from the current URL or from one that's passed in.
   * @param name: string Name of query param to get.
   * @param url: string Optional URL to use, otherwise current window URL is used.
   * @returns string
   */
  public getParamFromUrl(name: string, url?: string): string {
    const urlString = url || window.location.href;
    const parsed = this.parseUrl(urlString).searchObject;
    return (parsed && parsed[name]) || null;
  }

  public getPageFromSerpUrl(url?: string): string {
    const urlString = url || window.location.href;
    const path = this.parseUrl(urlString).pathname;
    return path.split('/')[6];
  }

  public getFragmentFromUrl(url?: string) {
    const urlString = url || window.location.href;
    return this.parseUrl(urlString).hash;
  }

  public getProfileReferrerParamsFromRoute(url?: string): string {
    const urlString = url || window.location.href;
    const positionMap = { search: 5, profile: 4, compare: 3 };
    const position = positionMap[urlString.split('/')[1]];
    return decodeURI(urlString.split('?')[0].split('/')[position]);
  }

  public getProfileParsedReferrerParamsFromRoute(url: string): any {
    let parsed;
    try {
      parsed = JSON.parse(this.getProfileReferrerParamsFromRoute(url));
    } catch (e) {
      parsed = {};
    }
    return parsed;
  }

  public getSearchParamType(source: any): SearchParamType {
    if (source && typeof source === 'object') {
      return this.lookupByParams(source);
    } else {
      return this.lookupBySearchRouteType(source);
    }
  }

  public getState(url?: string): string {
    url = url || window.location.href;
    const pageName = this.parseUrl(url)
      .pathname.split('/')
      .filter((value) => value !== '')[0];
    return pageName || 'home';
  }

  public getParamsFromString(queryStringParams: string): object {
    const objectRouteParams = {};
    const arrayRouteParams = queryStringParams.replace('?', '&').split('&');

    arrayRouteParams.forEach((param) => {
      const paramSplit = param.split('=');
      if (paramSplit[0] && paramSplit[1]) {
        objectRouteParams[paramSplit[0].replace('/?', '')] = decodeURIComponent(
          paramSplit[1]
        );
      }
    });

    return objectRouteParams;
  }

  public appendToLink(link: string, key: string, value: any): string {
    link += includes(link, '?') ? '&' : '?';
    link += key + '=' + value;

    return link;
  }

  public hideOnRoutes(url: string, routes: string[]): boolean {
    const urlRoute = this.getState(url);
    const urlParams = this.getParamsFromString(url);
    return this.matchRouteAndParams(routes, urlRoute, urlParams);
  }

  public buildProfileLink(provider_id, location_id, referrer): string {
    return (
      '/profile/' +
      provider_id +
      '/' +
      location_id +
      '/' +
      JSON.stringify(referrer)
    );
  }

  public mapSearchParams(url: string) {
    let out = {
      type: null,
      id: null,
    };

    if (url && this.getState(url) === 'search') {
      const urlSplit = url.split('/');
      out = {
        type: urlSplit[2],
        id: decodeURI(urlSplit[3]),
      };
    }

    return out;
  }

  /**
   * Get a search route state from given URL, ex: procedures or search_specialties
   * @param url: string
   * @returns SearchRouteType
   */
  public getSearchRouteState(url: string): SearchRouteType {
    if (url && this.getState(url) === 'search') {
      const urlSplit = url.split('/');
      return urlSplit[2] as SearchRouteType;
    }
  }

  public stripProtocol(link: string): string {
    return link.replace('http:', '').replace('https:', '');
  }

  public addHttpProtocol(url: string): string {
    // convert the protocol to lowercase
    if (/^(?:F|HT)TP(S)?\:\/\//.test(url))
      url = `${url.split(':')[0].toLowerCase()}:${url.split(':')[1]}`;

    // convert the url to lowercase only if the entire url string appears to be in uppercase
    if (
      url === url.toUpperCase() ||
      (/^(?:f|ht)(tp)s?\:\/\//.test(url) && url.substring(6) === url.substring(6).toUpperCase())
    )
      url = url.toLowerCase();

    // insert "http://" if the protocol is not present in the url
    if (
      !/^(?:f|F|ht|HT)(tp|TP)(s|S)?\:\/\//.test(url) &&
      url &&
      url.length > 1 &&
      url.substring(0, 2) !== '//'
    ) {
      url = 'http://' + url;
    }
    return url;
  }

  public parseCriticalParams(params): string {
    let out = '';

    this.criticalParams.forEach((key, index) => {
      if (params[key]) {
        out += index < 1 ? '?' : '&';
        out += key + '=' + params[key];
      }
    });

    return out;
  }

  public matchRouteAndParams(
    routes: string[],
    urlRoute: string,
    urlParams: any
  ): boolean {
    return !!find(routes, (state: string) => {
      const [route, param] = state.split('?');
      if (route === urlRoute) {
        if (param) {
          return !!urlParams[param];
        }
        return true;
      }
      return false;
    });
  }

  public removeSerpQueryParams(
    params: object,
    filterSettings: SearchFilter[]
  ): void {
    const searchParamsToRemove = ['page', 'billing_code', 'radius', 'sort'];
    filterSettings.forEach((searchFilter) => {
      if (searchFilter.type === 'nested_dropdown') {
        searchFilter.items.forEach((item) => {
          if (!!item.facet) {
            searchParamsToRemove.push(item.facet);
          }
        });
      } else {
        if (!!searchFilter.facet) {
          searchParamsToRemove.push(searchFilter.facet);
        }
      }
    });
    searchParamsToRemove.forEach((removeParam) => {
      delete params[removeParam];
    });
  }

  public cleanUrlParam(
    params: object,
    url: string,
    allowedStates: string[],
    paramToRemove: string
  ): void {
    const currentState = this.getState(url);
    if (
      !includes(allowedStates, currentState) &&
      params.hasOwnProperty(paramToRemove)
    ) {
      delete params[paramToRemove];
    }
  }

  public sortQueryParams(
    queryObject: any,
    excludeParams?: HttpMockConfigIgnoredQueryParam[]
  ): string {
    const sortedParams = [];
    Object.keys(queryObject)
      .sort()
      .forEach((key) => {
        const value = this.handleSortQueryParamsExclusion(
          key,
          queryObject,
          excludeParams
        );
        if (value) {
          const encodedKey = encodeURIComponent(key);

          if (Array.isArray(value)) {
            value.forEach(arrayValue => sortedParams.push(`${encodedKey}=${encodeURIComponent(arrayValue)}`));
          } else {
            sortedParams.push(`${encodedKey}=${encodeURIComponent(value)}`);
          }
        }
      });
    return sortedParams.join('&');
  }

  public stringifyParsedUrl(
    parsedUrl: ParsedUrl,
    options?: StringifyParsedUrlOptions
  ): string {
    options = new StringifyParsedUrlOptions(options);
    let url = '';
    if (options.fullyQualified) {
      url += `${parsedUrl.protocol}://${parsedUrl.host}`;
    }
    url += parsedUrl.pathObject.toString();

    if (options.includeSearch) {
      const queryParams = this.sortQueryParams(
        parsedUrl.searchObject,
        options.excludeQueryParams
      );
      if (queryParams) {
        url += `?${queryParams}`;
      }
      if (parsedUrl.hash) {
        url += `#${parsedUrl.hash}`;
      }
    }
    return url;
  }

  // URL Matcher with wildcard support
  // Example:
  // url = '/foo/1234/bar/1234/baz';
  // pattern = '/foo/*/bar/*/baz';
  // matchUrl(url, pattern) -> true
  // greedyMatch: If true, pattern must match full URL.
  public matchUrl(
    url: string,
    pattern: string,
    greedyMatch: boolean = false
  ): boolean {
    // Decode URL
    url = decodeURIComponent(url);
    pattern = pattern
      // Escape special characters
      .replace(/[\/\-\[\]\{\}\(\)\+\?\.\,\\\^\$\|\#\s]/g, '\\$&')
      // Update wildcard to pattern matcher
      .replace(/\*/g, '.*');

    const matchStart = greedyMatch ? '^' : '';
    const matchEnd = greedyMatch ? '$' : '';
    const regExp = new RegExp(`${matchStart}(\/?)${pattern}${matchEnd}`, 'g');
    return url.match(regExp) !== null;
  }

  // URL Query Param Matcher
  // Example:
  // url = '/foo?bar=baz&fiz=faz';
  // params = ['bar=baz'];
  // matchUrlQueryParams(url, params) -> true
  public matchUrlQueryParams(url: string, params: string[]): boolean {
    return (
      !params ||
      params.length === 0 ||
      params.every((param) => url.indexOf(param) !== -1)
    );
  }

  public goToUrl(url: string): void {
    window.location.href = url;
  }

  public origin(): string {
    const location = window.location;
    const portSequence = location.port ? ':' + location.port : '';
    return location.protocol + '//' + location.hostname + portSequence;
  }

  public manuallyEncodeParams(params: HttpParams): string {
    const encodedParams = [];

    params?.keys().forEach(key => {
      const value = params.get(key);
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      encodedParams.push(`${encodedKey}=${encodedValue}`);
    });

    return encodedParams.length ? `?${encodedParams.join('&')}` : '';
  }

  private lookupByParams(params: any): SearchParamType {
    if (params.hospital_affiliation_ids) {
      return 'hospital_affiliation_ids';
    } else if (params.group_affiliation_ids) {
      return 'group_affiliation_ids';
    } else if (params.procedure_id) {
      return 'procedure_id';
    } else if (params.search_specialty_id) {
      return 'search_specialty_id';
    } else if (params.name) {
      return 'name';
    }
  }

  private lookupBySearchRouteType(routeType: SearchRouteType): SearchParamType {
    const typeMap = {
      procedures: 'procedure_id',
      search_specialties: 'search_specialty_id',
      specialty_search: 'search_specialty_id',
      name: 'name',
      providers: 'name',
      hospital_affiliations: 'hospital_affiliation_ids',
      group_affiliations: 'group_affiliation_ids',
    };
    return typeMap[routeType] || null;
  }

  private handleSortQueryParamsExclusion(
    key: string,
    queryObject: any,
    excludeParams: HttpMockConfigIgnoredQueryParam[]
  ): string {
    let value = queryObject[key];
    if (excludeParams) {
      const exclude = excludeParams.find((item) => item.key === key);
      if (exclude) {
        if (exclude.pattern) {
          value = value.replace(exclude.pattern, '');
        } else {
          return null;
        }
      }
    }
    return value;
  }
}
