import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-gps-error-overlay',
  templateUrl: './gps-error-overlay.component.html',
  styleUrls: ['./gps-error-overlay.component.scss'],
})
export class GpsErrorOverlayComponent {
  constructor(public dialogRef: MatDialogRef<GpsErrorOverlayComponent>) {}
}
