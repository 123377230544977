import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsNextStepsComponent } from './ss-next-steps.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: [SsNextStepsComponent],
  exports: [SsNextStepsComponent],
})
export class SsNextStepsModule {}
