<zelis-list-item
  [primaryText]="name"
  [metadata]="metadata"
  [lozengeText]="lozengeText"
  lozengeColor="system-green"
  lozengeType="theme"
  lozengeEmphasis="high"
  [lozengeClasses]="lozengeClasses"
  [secondaryText]="secondaryText"
  [rightIconName]="iconName"
  rightIconClass="text-blue-500 w-4 h-4"
  [rightIconTooltipText]="tooltipText"
  rightIconTooltipPosition="above"
  linkClass="p-5"
  (clickFn)="selectItem.emit($event)"
></zelis-list-item>
