import { FlexLayoutModule } from '@angular/flex-layout';
import { CompanyLogoModule } from './../company-logo/company-logo.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalFooterComponent } from './global-footer.component';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [GlobalFooterComponent],
  imports: [
    CommonModule,
    CompanyLogoModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [GlobalFooterComponent],
})
export class GlobalFooterModule {}
