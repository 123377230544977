import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { GlobalMembersDropdownComponent } from './global-members-dropdown.component';

@NgModule({
  declarations: [GlobalMembersDropdownComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule],
  providers: [],
  exports: [GlobalMembersDropdownComponent],
})
export class GlobalMembersDropdownModule {}
