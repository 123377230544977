import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DlsIconModule } from '@zelis/dls/icons';
import { MatIconModule } from '@angular/material/icon';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { DialogService } from './dialog.service';
import { DlsButtonModule } from '@zelis/dls/button';
import { DialogComponent } from './dialog/dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    DlsIconModule,
    MatIconModule,
    DlsButtonModule,
  ],
  exports: [
    MatDialogModule,
    DialogHeaderComponent,
    DialogFooterComponent,
    DialogComponent,
  ],
  declarations: [DialogHeaderComponent, DialogFooterComponent, DialogComponent],
  providers: [DialogService],
})
export class DlsDialogModule {}
