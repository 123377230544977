import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { Member } from '../members';
import { MembersService } from '@services/members.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Router } from '@angular/router';
import { WindowService } from '@services/window.service';
import { AuthService } from '@services/auth.service';
import { Observable, of, Subscription } from 'rxjs';
import { FeaturesService } from '@services/features/features.service';
import { MsaSettingsService } from './msa.settings.service';
import { Msa } from '@interfaces/msa.model';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { RouteUtilities } from '@utilities/route.utilities';
import { NotificationOverlayComponent } from '@components/notification-overlay';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-msa-banner',
  templateUrl: './msa.banner.component.html',
  styleUrls: ['./msa.banner.component.scss'],
})
export class MsaBannerComponent implements OnDestroy {
  public member: Member;
  public msaAuth: boolean = false;
  public formBypass: boolean = false;
  public disableStartOver: boolean = false;
  private subscriptions = new SubscriptionManager();
  private suppressMembers: boolean = false;

  constructor(
    private membersService: MembersService,
    private authService: AuthService,
    private router: Router,
    public windowService: WindowService,
    private featuresService: FeaturesService,
    private msaSettingsService: MsaSettingsService,
    private store: Store<any>,
    private routeUtilities: RouteUtilities,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private settingsService: SettingsService
  ) {
    this.subscriptions.add(this.subscribeToFeatures());
    this.subscriptions.add(this.subscribeToMsaAuth());
    this.subscriptions.add(this.subscribeToMsaSso());
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public logout(): void {
    this.windowService['sessionStorage'].removeItem('msaFormSubmitted');
    this.authService.logoutMsa();
  }

  public newMember(): void {
    this.windowService['sessionStorage'].removeItem('msaFormSubmitted');
    this.windowService['location'].href =
      '/auth/SSOLogout?RelayState=' + this.routeUtilities.origin();
  }

  public showStartOver(): boolean {
    return this.disableStartOver ? false : !this.formBypass;
  }

  public subscribeToMsaAuth(): Subscription {
    return this.store
      .pipe(
        select(AuthStoreSelectors.getAuthStatus),
        filter((authStatus) => authStatus?.resolved),
        take(1),
        switchMap((data) => {
          if (data?.msa_auth_status) {
            this.formBypass = false;
            return this.subscribeToMember();
          }
          return of(null);
        })
      )
      .subscribe();
  }

  public subscribeToMsaSso(): Subscription {
    return this.msaSettingsService
      .getMsaSso()
      .pipe(
        filter((msaSso: Msa) => msaSso.sso),
        switchMap(() => {
          this.formBypass = true;
          return this.subscribeToMember();
        })
      )
      .subscribe();
  }

  private subscribeToMember(): Observable<Member> {
    return this.membersService.getMember().pipe(
      catchError((_error) => of(null)),
      tap((member) => this.handleMemberResponse(member))
    );
  }

  private handleMemberResponse(member: Member): void {
    this.member = member;
    if (!member || (member && !member['id'])) {
      this.router.navigate(['msaform']);
    }
    this.settingsService
      .getSetting('msa_error_notification_enabled')
      .subscribe((enabled) => {
        if (enabled && !this.suppressMembers && member && !member?.cost_service_member) {
          this.dialog.open(NotificationOverlayComponent, {
            width: '560px',
            data: {
              headline: this.translateService.instant('msa_notification_heading', { name: this.member.full_name || 'Name Missing' }),
              body: this.translateService.instant('msa_notification_text', { name: this.member.full_name || 'Name Missing' }),
              noClose: true,
              buttons: [
                {
                  translation: 'app_global_acknowledge',
                  method: 'closeModal',
                  external_link: false,
                },
              ],
              closeModal: () => this.dialog.closeAll(),
            },
          });
        }
      })
  }

  private subscribeToFeatures(): Subscription {
    return this.featuresService.getFeatureFlags().subscribe((features) => {
      this.disableStartOver =
        features && features.disable_msa_start_over_button
          ? features.disable_msa_start_over_button
          : false;
      this.suppressMembers = features?.suppress_members
    });
  }
}
