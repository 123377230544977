import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  NgZone,
} from '@angular/core';
import { PageAnimations } from '@utilities/page.animations';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { UuxPageConfiguration } from '@interfaces/uux-page-configuration.interface';
import { UuxService } from '@services/uux/uux.service';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { ConfigurationService } from '@services/configuration.service';
import { NetworksService } from '@services/networks.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import {finalize, first, take, takeWhile} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { NetworkToutService } from '@services/network-tout/network-tout.service';
import { GatedEntryService } from '@services/gated-entry/gated-entry.service';
import { GatedEntryStatus } from '@interfaces/gated-entry-status.interface';
import { Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { GoogleTagManagerService } from '@services/analytics/google-tag-manager.service';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";

@Component({
  selector: 'app-uux',
  templateUrl: './uux.component.html',
  styleUrls: ['./uux.component.scss'],
  animations: PageAnimations,
})
export class UuxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() globalHeader: boolean;
  @Input() showTout: boolean = false;
  @Input() open: boolean = false;
  @Output() openChange = new EventEmitter<boolean>();

  @ViewChild(MatMenuTrigger) dropdownTrigger: MatMenuTrigger;

  public currentStep: UuxPageConfiguration;
  public marketSegment: string;
  public ppoPlan: UuxNetwork;
  public alphaPrefixEnabled: boolean = false;
  public selectedState: string;
  public selectedNetwork: UuxNetwork;
  public uuxNetworks: UuxNetwork[];
  public alphaPrefixCode: string;
  public toutEnabled: boolean = false;
  public tout: boolean = false;
  public toutDismissed: boolean = false;

  private breadcrumbs: string[] = [];
  private subscriptions = new SubscriptionManager();
  private startingStep: UuxPageConfiguration = { page: 'home' };
  private storage: StorageUtilities = new StorageUtilities();
  private routeUtilities = new RouteUtilities();

  constructor(
    private uuxService: UuxService,
    private configurationService: ConfigurationService,
    private criticalParamsService: CriticalParamsService,
    private networksService: NetworksService,
    private networkToutService: NetworkToutService,
    private gatedEntryService: GatedEntryService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService,
    private ngZone: NgZone,
    private networkSelectWizardService: NetworkSelectionWizardService,
  ) {}

  ngOnInit() {
    this.breadcrumbs = this.storage.sessionStorageGet('uuxBreadcrumbs') || [];
    this.marketSegment = this.storage.sessionStorageGet('uuxMarketSegment');
    this.currentStep = this.storage.sessionStorageGet('uuxCurrentStep') || {
      ...this.startingStep,
    };
    this.storage.sessionStorageSet('uuxCurrentStep', this.currentStep);
    this.selectedNetwork = this.storage.sessionStorageGet('uuxSelectedNetwork')
    this.selectedState = this.storage.sessionStorageGet('uuxStateCode');
    this.uuxNetworks = this.storage.sessionStorageGet('uuxPlans');
    this.getAlphaPrefixConfig();
    this.resolveToUrlCi();
    this.setPlans();
    this.navigatedHome();
    this.checkForSelectedNetwork();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.open &&
      changes.open.currentValue &&
      this.dropdownTrigger
    ) {
      this.dropdownTrigger.openMenu();
    }
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onGoBack(): void {
    this.currentStep.direction = 'back';
    this.changeDetectorRef.detectChanges();
    this.currentStep.page = this.breadcrumbs.pop();
    this.removeAlphaSelected();
    this.clearMarketAndState();
    this.backHome();
    this.storage.sessionStorageSet('uuxCurrentStep', this.currentStep);
    this.storage.sessionStorageSet('uuxBreadcrumbs', this.breadcrumbs);
  }

  public onGoToPage(page: string): void {
    this.currentStep.direction = 'forward';
    this.changeDetectorRef.detectChanges();
    this.breadcrumbs.push(this.currentStep.page);
    this.currentStep.page = page;
    this.clearMarketAndState();
    this.storage.sessionStorageSet('uuxCurrentStep', this.currentStep);
    this.storage.sessionStorageSet('uuxBreadcrumbs', this.breadcrumbs);
  }

  public closeDropdown(): void {
    this.dropdownTrigger.closeMenu();
  }

  public onSetMarketSegment(marketSegment: string): void {
    this.marketSegment = marketSegment;
    this.storage.sessionStorageSet('uuxMarketSegment', this.marketSegment);
  }

  // this.alphaPrefixCode is reassigned here to account for changes in network on SERP where it may be null
  public onSelectUuxNetwork(uuxNetwork: UuxNetwork): void {
    if (!uuxNetwork) {
      return this.handleUndefinedNetwork();
    }

    const ciFromUrl = this.routeUtilities.getParamFromUrl('ci');
    this.alphaPrefixCode =
      this.alphaPrefixCode ||
      this.selectedNetwork?.alphaPrefCd ||
      uuxNetwork.alphaPrefCd;
    this.selectedNetwork = uuxNetwork;
    this.selectedNetwork.ci = this.selectedNetwork.ci || ciFromUrl;
    this.selectedNetwork.alphaPrefCd =
      this.selectedNetwork.alphaPrefCd || this.alphaPrefixCode;
    this.storage.sessionStorageSet('uuxSelectedNetwork', uuxNetwork);
    this.networkSelectWizardService.saveSelectedPlan(uuxNetwork);
    this.gtmPlanSelect(uuxNetwork);

    if (this.selectedNetwork.id) {
      this.selectNetwork(this.selectedNetwork);
    } else {
      if (this.globalHeader) {
        this.storage.sessionStorageSet('uuxNavigatedHome', true);
        this.uuxService.updateUuxNetworkChangeTooltip(true);
        this.criticalParamsService.setCriticalParams({
          ci: this.selectedNetwork.ci,
        });
        this.router.navigate(['/'], {
          queryParams: { ci: this.selectedNetwork.ci },
          queryParamsHandling: 'merge',
        });
        return;
      }
      this.selectPlan(uuxNetwork);
    }
  }

  public selectPlan(uuxNetwork: UuxNetwork): void {
    this.subscriptions.add(
      this.configurationService
        .getSignatureByParam({ identifier: uuxNetwork.ci }, true)
        .subscribe(() => {
          if (!uuxNetwork.defaultPlan) {
            this.setMatchingPage();
            this.closeDropdown();
          }
          this.marketSegmentSelected();
          this.storage.sessionStorageRemove('uuxBreadcrumbs');
        })
    );
  }

  public selectNetwork(uuxNetwork: UuxNetwork): void {
    this.subscriptions.add(
      this.configurationService
        .getSignatureByParam({ alphaPrefCd: this.alphaPrefixCode }, true)
        .pipe(first())
        .subscribe(() => {
          this.networksService.setNetwork(uuxNetwork.id);
          this.setMatchingPage();
          this.storage.sessionStorageRemove('uuxBreadcrumbs');
          this.closeDropdown();
        })
    );
  }

  public onInitSelect(uuxNetwork: UuxNetwork): void {
    this.selectedNetwork = uuxNetwork;
    this.initNetworkTout();
  }

  public onDropdownClosed(): void {
    this.currentStep.direction = 'none';
    this.open = false;
    this.openChange.emit(this.open);
  }

  public onTooltipClose(): void {
    this.storage.sessionStorageSet('toutNetworkSelection', false);
    this.tout = false;
    this.toutDismissed = true;
  }

  private handleUndefinedNetwork(): void {
    this.storage.sessionStorageRemove('uuxSelectedNetwork');
    this.clearMarketAndState();
    this.storage.sessionStorageSet('useBasePlan', true);
    this.refreshNetworksList();
    if (this.globalHeader) {
      this.storage.sessionStorageSet('uuxNavigatedHome', true);
      this.uuxService.updateUuxNetworkChangeTooltip(true);
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }

  private navigatedHome(): void {
    if (this.storage.sessionStorageGet('uuxNavigatedHome')) {
      this.storage.sessionStorageRemove('uuxNavigatedHome');
      this.selectPlan(this.selectedNetwork);
    }
  }

  private getAlphaPrefixConfig(): void {
    this.subscriptions.add(
      this.uuxService
        .getAlphaPrefixConfig()
        .subscribe((alpha_prefix_enabled: boolean) => {
          this.alphaPrefixEnabled = alpha_prefix_enabled;
        })
    );
  }

  private removeAlphaSelected(): void {
    if (this.currentStep.page === 'alpha') {
      this.storage.sessionStorageRemove('uuxAlphaSelected');
    }
  }

  private setMatchingPage(): void {
    this.currentStep = { page: 'matching' };
    this.storage.sessionStorageSet('uuxCurrentStep', this.currentStep);
  }

  private initNetworkTout(): void {
    if (this.showTout && this.selectedNetwork) {
      this.subscriptions.add(
        combineLatest([
          this.gatedEntryService.status,
          this.networkToutService.getToutEnabled(
            this.selectedNetwork.defaultPlan
          ),
        ]).subscribe(
          ([gatedEntryStatus, enabled]: [GatedEntryStatus, boolean]) => {
            if (gatedEntryStatus.isClosed || !gatedEntryStatus.isEnabled) {
              this.selectedNetwork = this.storage.sessionStorageGet('uuxSelectedNetwork');
              this.toutEnabled = enabled;
              this.tout = this.toutEnabled;
              if (this.tout) {
                this.startTout();
              }
            }
          }
        )
      );
    }
  }

  private startTout(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => this.ngZone.run(() => (this.tout = false)), 4000);
    });
  }

  private resolveToUrlCi(): void {
    const ciFromUrl = this.routeUtilities.getParamFromUrl('ci');
    if (this.selectedNetwork && ciFromUrl !== this.selectedNetwork.ci) {
      const matchedNetwork = this.uuxNetworks.find((el) => el.ci === ciFromUrl);
      if (matchedNetwork) {
        this.selectedNetwork = matchedNetwork;
        this.selectPlan(this.selectedNetwork);
      }
    }
  }

  private gtmPlanSelect(uuxNetwork: UuxNetwork): void {
    if (!uuxNetwork.defaultPlan) {
      const gtmEvent =
        this.currentStep.page === 'home'
          ? 'network-tout'
          : 'find-your-plan-terminal';
      this.googleTagManagerService.pushToDataLayer({
        event: gtmEvent,
        label: uuxNetwork.displayName,
      });
    }
  }

  private backHome(): void {
    const { page, direction } = this.currentStep;
    if (page === 'home' && direction === 'back') {
      this.refreshNetworksList();
    }
  }

  private marketSegmentSelected(): void {
    if (this.currentStep.page === 'matching' && this.marketSegment) {
      this.refreshNetworksList();
    }
  }

  private setPlans(): void {
    const corpCode = this.routeUtilities.getParamFromUrl('corp_code');
    const locale = this.routeUtilities.getParamFromUrl('locale');
    const params = this.setGetPlansParams(corpCode, locale);
    this.subscriptions.add(
      this.uuxService.getPlansAndCi(params).pipe(take(1)).subscribe(([ci, plans]) => {
        this.findAndSetPlans(plans, locale, ci);
      })
    );
  }

  private refreshNetworksList(): void {
    const corpCode = this.routeUtilities.getParamFromUrl('corp_code');
    const locale = this.routeUtilities.getParamFromUrl('locale');
    const params = this.setGetPlansParams(corpCode, locale);
    this.subscriptions.add(
      this.uuxService
        .getPlansAndCi(params)
        .pipe(take(1))
        .subscribe(([ci, plans]) => this.findAndSetPlans(plans, locale, ci))
    );
  }

  private findAndSetPlans(
    plans: UuxNetwork[],
    locale: string,
    ci: string
  ): void {
    const isAlpha = this.storage.sessionStorageGet('uuxAlphaSelected');
    const savedPlan = this.storage.sessionStorageGet('uuxSelectedNetwork');
    const basePlan = this.findAndSetBasePlan(plans);
    plans = isAlpha ? this.storage.sessionStorageGet('uuxPlans') : plans;
    if (!isAlpha && (!savedPlan || this.localeChanged(locale))) {
      ci = this.storage.sessionStorageGet('useBasePlan')
        ? this.useBaseCI(basePlan)
        : ci;
      this.findAndSetPlanByCi(plans, ci);
    }
    if (!isAlpha && basePlan) {
      plans = this.sortPlansAlphabetically(plans, basePlan);
    }
    this.storage.sessionStorageSet('uuxPlans', plans);
    this.uuxNetworks = plans;
    this.storage.sessionStorageSet('uuxLocale', locale);
  }

  private findAndSetPlanByCi(plans: UuxNetwork[], ci: string): void {
    const savedPlan = this.storage.sessionStorageGet('uuxSelectedNetwork');
    let ciPlanMatch = plans.find((uuxNetwork: UuxNetwork) => {
      if (savedPlan) {
        return (
          uuxNetwork.ci === ci &&
          savedPlan.displayName === uuxNetwork.displayName
        );
      }
      return uuxNetwork.ci === ci;
    });
    if (savedPlan && !ciPlanMatch) {
      ciPlanMatch = plans.find((uuxNetwork: UuxNetwork) => {
        return uuxNetwork.ci === ci;
      });
    }
    this.storage.sessionStorageSet('uuxSelectedNetwork', ciPlanMatch);
    this.onInitSelect(ciPlanMatch);
  }

  private useBaseCI(basePlan: UuxNetwork): string {
    this.criticalParamsService.setCriticalParams({ ci: basePlan.ci });
    this.storage.sessionStorageRemove('useBasePlan');
    return basePlan.ci;
  }

  private findAndSetBasePlan(plans: UuxNetwork[]): UuxNetwork {
    const basePlan = plans.find(
      (uuxNetwork: UuxNetwork) => uuxNetwork.defaultPlan
    );
    if (basePlan) {
      this.storage.sessionStorageSet('uuxBasePlan', basePlan);
    }
    return basePlan;
  }

  private localeChanged(locale: string): boolean {
    return locale !== this.storage.sessionStorageGet('uuxLocale');
  }

  private clearMarketAndState(): void {
    if (this.currentStep.page === 'home') {
      this.marketSegment = null;
      this.selectedState = null;
      this.storage.sessionStorageRemove('uuxMarketSegment');
      this.storage.sessionStorageRemove('uuxStateCode');
      this.storage.sessionStorageRemove('uuxStates');
      this.uuxNetworks = this.storage.sessionStorageGet('uuxPlans');
      if (this.uuxNetworks) {
        const basePlan = this.findAndSetBasePlan(this.uuxNetworks);
        this.uuxNetworks = this.sortPlansAlphabetically(
          this.uuxNetworks,
          basePlan
        );
      }
    }
  }

  private setGetPlansParams(corpCode: string, locale: string): any {
    const params: any = { corp_code: corpCode, locale: locale };
    if (this.marketSegment) {
      params.market_segment = this.marketSegment;

      if (this.selectedState) {
        params.state_code = this.selectedState;
      }
    }
    return params;
  }

  private checkForSelectedNetwork(): void {
    this.gatedEntryService.status.pipe(takeWhile(status => status.isOpened),
      finalize(() => {
        if (this.selectedNetwork.displayName !== this.storage.sessionStorageGet('uuxSelectedNetwork').displayName) {
          this.selectedNetwork = this.storage.sessionStorageGet('uuxSelectedNetwork')
        }
      })).subscribe();
  }

  private sortPlansAlphabetically(
    plans: UuxNetwork[],
    basePlan: UuxNetwork
  ): UuxNetwork[] {
    plans = plans
      .filter((plan) => plan !== basePlan)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
    plans = [basePlan, ...plans];
    return plans;
  }
}
