import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthStatus } from '@interfaces/auth-status.model';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
@Component({
  selector: 'app-wizard-login-link',
  templateUrl: './wizard-login-link.component.html',
})

export class WizardLoginLinkComponent implements OnInit, OnDestroy {
  public auth: AuthStatus;
  private subscriptions = new SubscriptionManager();

  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    this.setAuth();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  private setAuth(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(AuthStoreSelectors.getAuthStatus))
        .subscribe((auth: AuthStatus) => (this.auth = auth))
    );
  }
}
