<div class="alpha-list-menu-item" ngClass.lt-md="mobile">
  <h5
    class="legend-container"
    *ngIf="showAlphaLetter(index)"
    (click)="$event.stopPropagation()"
    [id]="item.prefix"
    tabindex="-1"
  >
    {{ item.prefix | uppercase }}
  </h5>
  <app-alpha-list-item
    #alphaListOption
    *ngIf="!item.suppress_display"
    class="guided-search-option guided-search-a-z-option"
    appGtm
    [gtmOnClick]="true"
    [gtmOnClickIf]="carepathEnabled && item.episode_procedure"
    [gtmData]="{
      event: 'gtm.episodes.browse.view_carepath',
      episodes_procedure_name: item.name
    }"
    (keydown)="itemKeydown.emit($event)"
    (selectItem)="selected.emit(item)"
    eventPreventDefault
    eventStopPropagation
    [name]="item.name"
    [description]="item.description"
    [highIncentiveAmount]="item.high_incentive_amount"
    [lozengeClasses]="item.description === 'Specialty' ? 'incentives-lozenge-quality' : 'incentives-lozenge-smartshopper'"
    [episodeProcedure]="carepathEnabled && item.episode_procedure"
    [translations]="{
      upTo: 'guided_search_incentives_earn_up_to' | translate,
      noDescription: 'guided_search_no_description' | translate,
      earnInfo: 'smartshopper_earn_info_guided_search_general' | translate
    }"
  >
  </app-alpha-list-item>
</div>
