<div
  class="close-button"
  [ngClass]="{ 'high-contrast-theme': contrast === 'dark' }"
>
  <button
    *ngIf="type === 'icon'"
    mat-icon-button
    (click)="closeCalled.emit($event)"
    (keydown.enter)="closeCalled.emit($event)"
    [attr.aria-label]="'app_global_close' | translate"
    data-cy="close-button"
  >
    <mat-icon svgIcon="dls-times-plain-light">
    </mat-icon>
  </button>
  <button
    *ngIf="type === 'text'"
    mat-button
    class="text-close"
    (click)="closeCalled.emit($event)"
    (keydown.enter)="closeCalled.emit($event)"
    (keydown.escape)="closeCalled.emit($event)"
    data-cy="close-button"
  >
    {{ closeText }}
  </button>
</div>
