import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CloseButtonModule } from '../../close-button/close-button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxQuestionnaireComponent } from './uux-questionnaire.component';
import { GtmModule } from '../../../directives/gtm/gtm.module';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [UuxQuestionnaireComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    CloseButtonModule,
    GtmModule,
    DlsIconModule,
  ],
  exports: [UuxQuestionnaireComponent],
})
export class UuxQuestionnaireModule {}
