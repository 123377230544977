import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { NetworkAlphaPrefixDialogData } from '@interfaces/network-alpha-prefix-dialog-data.interface';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MemberCardMagnify,
  SubscriptionManager,
} from '@zelis/platform-ui-components';
import { NetworksService } from '../../services/networks.service';
import { Network } from '@interfaces/network.model';
import { first, map } from 'rxjs/operators';
import { NetworkAlphaPrefixDialogCloseData } from '@interfaces/network-alpha-prefix-dialog-close-data.interface';
import { SettingsService } from '../../services/settings.service';
import { Observable } from 'rxjs';
import { AlphaPrefixService } from '../../services/alpha-prefix.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-network-alpha-prefix-dialog',
  templateUrl: './network-alpha-prefix-dialog.component.html',
  styleUrls: ['./network-alpha-prefix-dialog.component.scss'],
})
export class NetworkAlphaPrefixDialogComponent implements OnInit, OnDestroy {
  public alphaPrefixForm: UntypedFormGroup = new UntypedFormGroup({
    alphaPrefix: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(this.data.alphaPrefixLength),
      Validators.maxLength(this.data.alphaPrefixLength),
    ]),
    network: new UntypedFormControl(null, [Validators.required]),
  });
  public memberCardMagnify: MemberCardMagnify;
  public networks: Network[];
  public suppressLogo: boolean = false;
  public showProgressBar: boolean = false;

  private subscriptions = new SubscriptionManager();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NetworkAlphaPrefixDialogData,
    private dialogRef: MatDialogRef<NetworkAlphaPrefixDialogComponent>,
    private networksService: NetworksService,
    private settingsService: SettingsService,
    private alphaPrefixService: AlphaPrefixService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.initMemberCardMagnify();
    this.requestSuppressLogo().subscribe(
      (setting) => (this.suppressLogo = setting)
    );
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onAlphaPrefixChange(): void {
    const alphaPrefix = this.alphaPrefixForm.get('alphaPrefix');
    if (alphaPrefix.valid) {
      if (this.data.requestCi) {
        this.showProgressBar = true;
        return this.getNetworksByAlpha(alphaPrefix.value);
      }
      this.filterNetworksByPrefix(alphaPrefix.value);
    }
  }

  public onAlphaPrefixSubmit(): void {
    const network = this.alphaPrefixForm.get('network').value;
    if (this.data.requestCi) {
      this.resolveNetworkByAlpha(network);
    } else {
      this.dialogRef.close({
        network_id: network,
      } as NetworkAlphaPrefixDialogCloseData);
    }
  }

  public onAlphaPrefixFocus(): void {
    this.magnifyAlphaPrefix();
  }

  public onNetworkFocus(): void {
    this.magnifyPlanName();
  }

  private initMemberCardMagnify(): void {
    setTimeout(() => this.magnifyAlphaPrefix(), 800);
  }

  private filterNetworksByPrefix(prefix: string): void {
    this.networksService.mappedNetworks
      .pipe(
        first((networks: Network[]) => !!(networks && networks.length)),
        map((networks: Network[]) =>
          networks.filter(
            (item: Network) => (item.prefix || '').indexOf(prefix) !== -1
          )
        )
      )
      .subscribe((networks: Network[]) => {
        this.setNetworks(networks);
        this.setNetworkFieldValue(networks);
      });
  }

  private getNetworksByAlpha(alphaPrefixCode: string): void {
    this.subscriptions.add(
      this.alphaPrefixService
        .getNetworksByAlphaPrefix(alphaPrefixCode)
        .subscribe((networks: Network[]) => {
          this.showProgressBar = false;
          this.setNetworks(networks);
          this.setNetworkFieldValue(networks);
        })
    );
  }

  private setNetworks(networks: Network[]): void {
    this.networks = networks;
  }

  private setNetworkFieldValue(networks: Network[]): void {
    let networkId = null;
    if (networks.length === 1) {
      networkId = networks[0].id;
    }
    this.alphaPrefixForm.get('network').patchValue(networkId);
    if (networks.length > 1) {
      this.magnifyPlanName();
    }
  }

  private magnifyAlphaPrefix(): void {
    this.memberCardMagnify = 'ALPHA_PREFIX';
  }

  private magnifyPlanName(): void {
    this.memberCardMagnify = 'PLAN_NAME';
  }

  private requestSuppressLogo(): Observable<boolean> {
    return this.settingsService.getSetting('suppress_member_card_logo');
  }

  private resolveNetworkByAlpha(network: string): void {
    const alphaPrefix = this.alphaPrefixForm.get('alphaPrefix').value;
    this.subscriptions.add(
      this.configurationService
        .getSignatureByParam({ alphaPrefCd: alphaPrefix }, true)
        .subscribe(() => {
          return this.dialogRef.close({
            network_id: network,
          } as NetworkAlphaPrefixDialogCloseData);
        })
    );
  }
}
