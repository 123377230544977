import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GatedEntryOverlayNetworkLocationComponent } from './gated-entry-overlay-network-location.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GlobalNetworkDropdownModule } from '../../global-header/network/global-network-dropdown.module';
import { GlobalLocationSelectModule } from '../../global-header/location/global-location-select.module';

@NgModule({
  declarations: [GatedEntryOverlayNetworkLocationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    GlobalLocationSelectModule,
    GlobalNetworkDropdownModule,
  ],
  exports: [GatedEntryOverlayNetworkLocationComponent],
})
export class GatedEntryOverlayNetworkLocationModule {}
