import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CloseButtonModule } from '../close-button/close-button.module';
import { GpsErrorOverlayComponent } from './gps-error-overlay.component';

@NgModule({
  declarations: [GpsErrorOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    CloseButtonModule,
  ],
  providers: [],
  exports: [GpsErrorOverlayComponent],
})
export class GpsErrorOverlayModule {}
