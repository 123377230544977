<div *ngIf="marketSegment === marketSegments.employer">
  <zelis-progress-spinner
    class="flex justify-center"
    *ngIf="!states?.length || loading"
  ></zelis-progress-spinner>
  <span *ngIf="!loading">
    <h4
      class="text-center pad all-3x"
      [innerHTML]="'uux_subheader_questionnaire_states' | translate"
    ></h4>
    <div
      *ngFor="let state of states; let i = index; trackBy: trackByStateCode"
      mat-menu-item
      (click)="selectState(state.state_code)"
    >
      <div
        [innerHTML]="state.state_name"
        [ngClass]="{ 'text-bold': i === 0 }"
      ></div>
    </div>
  </span>
</div>

<div *ngIf="marketSegment !== marketSegments.employer">
  <app-uux-plan-network-list
    [uuxNetworks]="uuxNetworks"
    [planListHeaderTranslationKey]="'uux_subheader_questionnaire_plans'"
    [marketSegment]="marketSegment"
    (goToPage)="goToPage.emit($event)"
    (selectUuxNetwork)="selectUuxNetwork.emit($event)"
  >
  </app-uux-plan-network-list>
</div>
