import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalancesStickyFabComponent } from './balances-sticky-fab.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BalancesModule } from '../balances/balances.module';
import { FabPeekerModule } from '../fab-peeker/fab-peeker.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  exports: [BalancesStickyFabComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BalancesModule,
    FabPeekerModule,
    TooltipModule,
    DlsIconModule,
  ],
  declarations: [BalancesStickyFabComponent],
})
export class BalancesStickyFabModule {}
