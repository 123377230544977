import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [ProgressSpinnerComponent],
  declarations: [ProgressSpinnerComponent],
})
export class DlsProgressSpinnerModule {}
