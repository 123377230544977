import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CloseButtonModule } from '../../close-button/close-button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UuxPlanNetworkListModule } from '../uux-plan-network-list/uux-plan-network-list.module';
import { UuxHomeComponent } from './uux-home.component';
import { UuxHelpFindPlanModule } from '../uux-help-find-plan/uux-help-find-plan.module';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [UuxHomeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    CloseButtonModule,
    UuxPlanNetworkListModule,
    UuxHelpFindPlanModule,
    DlsIconModule,
  ],
  exports: [UuxHomeComponent],
})
export class UuxHomeModule {}
