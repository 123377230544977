<div
  class="location-of-care m-4 md:m-0 text-center text-palette-m3-system-gray-10 flex flex-col items-center"
  data-cy="location-of-care"
>
  <div *ngIf="isDefaultLocationPage" class="default-header-content flex flex-col items-center">
    <h2 class="header mb-2">{{ 'network_selection_wizard_location_header' | translate }}</h2>
    <div class="max-w-[618px]">
      <div class="text-base mb-6">{{ 'network_selection_wizard_location_description' | translate }}</div>
      <mat-divider class="w-full"></mat-divider>
    </div>
  </div>

  <div class="body-container flex flex-col items-center max-w-[618px]">
    <div class="sub-header-content my-10">
      <h3 class="font-bold mb-2">
        {{searchForCareTitle | translate}}
      </h3>
      <div class="text-base">{{ 'network_selection_wizard_location_search_for_care_description' | translate }}</div>
    </div>

    <div class="location-select-container flex flex-col items-center w-full max-w-[584px] pt-2">
      <app-global-location-select
        class="w-full"
        appearance="fill"
        [ngClass]="{'app-form-field-error': !loggedIn && locationSelected && noValidNetworks}"
        (click)="openMobileLocation()"
        [mobileOverlay]="false"
        [results]="locationAutosuggest.cities"
        [selectedOption]="locationSelected || hasSelectedOption ? locationAutosuggest.selectedLocation : null"
        [isWizardExperience]="true"
        (termEntered)="locationAutosuggest.onLocationEntered($event)"
        (termSelected)="onLocationSelect($event)"
        (emitBrowserLocationRequested)="onGpsLocateSelect()"
        (optionSelected)="onOptionSelect($event)"
        data-cy="network-selection-wizard-location-select"
      >
      </app-global-location-select>
      <!-- Network Error Message -->
      <div
        class="text-error margin top-2x"
        *ngIf="!loggedIn && locationSelected && noValidNetworks"
      >
        {{ 'network_selection_wizard_location_no_networks_error' | translate }}
      </div>
      <div class="mt-8 w-[240px]">
        <zelis-button
          fullWidth="true"
          size="medium"
          variant="flat"
          color="system-blue"
          (click)="onContinueClick()"
          [disabled]="continueButtonDisabled()"
        >
          {{ 'app_global_continue' | translate }}
        </zelis-button>
      </div>
      <app-wizard-login-link class="mt-4"></app-wizard-login-link>
    </div>
  </div>
</div> 