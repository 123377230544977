import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalScrollComponent } from './horizontal-scroll.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HorizontalScrollItemDirective } from './horizontal-scroll-item.directive';
import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  exports: [HorizontalScrollComponent, HorizontalScrollItemDirective],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    NgxPageScrollModule,
  ],
  declarations: [HorizontalScrollComponent, HorizontalScrollItemDirective],
})
export class HorizontalScrollModule {}
