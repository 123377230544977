import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
})
export class NotificationOverlayComponent {
  constructor(
    public dialogRef: MatDialogRef<NotificationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
