<div class="hierarchy-list-nested-header" (click)="goBack()">
  <mat-icon svgIcon="dls-angle-plain-left-light" class="back-icon"></mat-icon>
  <h4>{{ title | translate }}</h4>
</div>
<ul class="hierarchy-list-nested-dropdown">
  <li
    *ngFor="let option of options; trackBy: trackByOption; index as i"
    (click)="facetOptionSelected(option)"
    [attr.data-cy]="'hierarchy-list-nested-options.mobile-filter-option-' + i"
    class="
      flex items-center justify-between
      mobile-filter-option-{{option.name || option.translation}}
      serp-filter-option-{{ filter.facet }}
    "
    [ngClass]="{ selected: isSelectedOption(option) }"
  >
    {{ option.name || option.translation | translate }}
    <mat-icon
      svgIcon="dls-check-plain-light"
      class="selected-checkmark h-[30px] w-[30px]"
      *ngIf="isSelectedOption(option)"
      [attr.aria-label]="'app_global_selected' | translate"
    >
    </mat-icon>
  </li>
</ul>
